import { Settings, Auth } from '../../../agent';
import { loading, stopLoading } from '../Authentication/actions';

export const SAVE_SETTINGS = 'SAVE_SETTINGS';
export const SAVE_SETTINGS_SUCCESS = 'SAVE_SETTINGS_SUCCESS';
export const SAVE_SETTINGS_FAILURE = 'SAVE_SETTINGS_FAILURE';

export const saveSettingsSuccess = payload => {
  return {
    type: SAVE_SETTINGS_SUCCESS,
    payload
  };
};

export const saveSettingsFailure = payload => {
  return {
    type: SAVE_SETTINGS_FAILURE,
    payload
  };
};

export const saveSettings = (settings, settingsId, successCallback) => {
  return dispatch => {
    dispatch({
      type: SAVE_SETTINGS
    });
    dispatch(loading());
    Settings.update(settings, settingsId)
      .then(r => {
        dispatch(saveSettingsSuccess(r.data));
        Auth.saveSettingsLocalStorage(r.data);
        successCallback();
      })
      .catch(e => dispatch(saveSettingsFailure(e)))
      .finally(() => dispatch(stopLoading()));
  };
};

export const LOAD_SETTINGS = 'LOAD_SETTINGS';
export const LOAD_SETTINGS_SUCCESS = 'LOAD_SETTINGS_SUCCESS';
export const LOAD_SETTINGS_FAILURE = 'LOAD_SETTINGS_FAILURE';

export const loadSettingsSuccess = settings => ({
  type: LOAD_SETTINGS_SUCCESS,
  payload: settings
});

export const loadSettingsFailure = e => ({
  type: LOAD_SETTINGS_FAILURE,
  payload: e
});

export const loadSettings = () => {
  return dispatch => {
    dispatch(loading());
    Settings.get()
      .then(r => dispatch(loadSettingsSuccess(r.data)))
      .catch(e => dispatch(loadSettingsFailure(e)))
      .finally(() => dispatch(stopLoading()));
    dispatch({
      type: LOAD_SETTINGS
    });
  };
};
