import React from 'react';
import { Table } from 'semantic-ui-react';
import styles from './styles.module.css';
import { useGoTo } from '../../../hooks/useRouterHooks';
import { timeHelper } from '../../../utils/timeHelper';

const ProjectList = ({ projects }) => {
  const [goTo] = useGoTo();

  const listProjects = ({ id, name, duration }) => {
    return (
      <Table.Row
        key={id}
        onClick={() => goTo(`projects/${id}`)}
        className={styles.project}
      >
        <Table.Cell className={styles.projectName}>{name}</Table.Cell>
        <Table.Cell>{timeHelper.displayTimeForHuman(duration)}</Table.Cell>
      </Table.Row>
    );
  };
  return (
    <Table className={styles.table} basic="very" selectable>
      <Table.Header>
        <Table.Row className={styles.header}>
          <Table.HeaderCell>Name</Table.HeaderCell>
          <Table.HeaderCell>Time Spent</Table.HeaderCell>
        </Table.Row>
      </Table.Header>

      <Table.Body>{projects.map(listProjects)}</Table.Body>
    </Table>
  );
};

export default ProjectList;
