import React, { useState } from 'react';
import { Input, Form, Button } from 'semantic-ui-react';
import { Link } from 'react-router-dom';
import { useAlert } from '../../../hooks/useAlert';
import * as styles from './styles.module.css';

const SignUp = ({ onSuccess }) => {
  const [email, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [isSubmmited, setSubmitted] = useState(false);

  const { showWarning } = useAlert();

  const isFormValid = () => {
    const ifFieldsAreEmpty = email === '' || password === '';

    if (ifFieldsAreEmpty) {
      showWarning('All the fields are required');
      return;
    }

    const emailIsValid = email =>
      /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(email);
    const passwordIsValid = password => password.length >= 8;

    if (emailIsValid(email) === false) {
      showWarning('The email is not valid');
      return;
    }

    if (passwordIsValid(password) === false) {
      showWarning('The password has to be 8 characters long');
      return;
    }

    return true;
  };

  const signUp = () => {
    setSubmitted(true);
    if (isFormValid()) {
      onSuccess({ username: email.trim(), password });
    }
  };

  return (
    <div className={styles.wrapper}>
      <div className={styles.form}>
        <Form onSubmit={signUp}>
          <h1 className={styles.title}>Productivity Hub</h1>
          <h2>Sign Up</h2>
          <p>
            Improve your work focusing on what matters using the pomodoro
            technique.
          </p>
          <Form.Field
            className="form-ph-input"
            error={isSubmmited && email === ''}
          >
            <label>Email</label>
            <Input
              name="email"
              type="email"
              onChange={e => setUsername(e.target.value)}
            ></Input>
          </Form.Field>
          <Form.Field
            className="form-ph-input"
            error={isSubmmited && password === ''}
          >
            <label>Password</label>
            <Input
              name="password"
              type="password"
              onChange={e => setPassword(e.target.value)}
            ></Input>
          </Form.Field>
          <Button className={styles.button} type="submit">
            Sign Up
          </Button>
          <div className={styles.alternativeAction}>
            or
            <Link to="/login"> Login</Link>
          </div>
        </Form>
      </div>
    </div>
  );
};

export default SignUp;
