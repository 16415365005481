import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import PomodoroForm from './PomodoroForm';
import {
  getProjects,
  getPomodoroProject,
  getPomodoroDuration
} from './selectors';
import { fetchProjects } from '../Projects/actions';
import {
  fetchHistory,
  changePomodoroDuration,
  selectPomodoroProject
} from './actions';
import { useHistory } from 'react-router-dom';

const PomodoroFormContainer = () => {
  const dispatch = useDispatch();
  const history = useHistory();

  const projects = useSelector(getProjects);
  const duration = useSelector(getPomodoroDuration);
  const project = useSelector(getPomodoroProject);

  useEffect(() => {
    const onFetchProjectsSuccess = () => {
      history.push('/wizard');
    };

    dispatch(fetchProjects(onFetchProjectsSuccess));
    dispatch(fetchHistory());
  }, [dispatch, history]);

  return (
    <PomodoroForm
      pomodoro={{ project, duration }}
      projects={projects}
      onDurationChange={duration => dispatch(changePomodoroDuration(duration))}
      onProjectChange={project => dispatch(selectPomodoroProject(project))}
    ></PomodoroForm>
  );
};

export default PomodoroFormContainer;
