import React from 'react';
import { CircularProgressbarWithChildren } from 'react-circular-progressbar';
import styles from './styles.module.css';

const Timer = ({ percentage, remainingTime, children, projectSelected }) => {
  const project =
    projectSelected.length > 16
      ? projectSelected.slice(0, 13).concat('...')
      : projectSelected;
  return (
    <div className={styles.timer}>
      <CircularProgressbarWithChildren
        value={percentage * 100}
        styles={{
          root: {},
          path: {
            stroke: `rgba(4, 181, 4, ${percentage})`,
            strokeLinecap: 'butt',
            transition: 'stroke-dashoffset 0.5s ease 0s',
            transform: 'rotate(0.25turn)',
            transformOrigin: 'center center'
          },
          trail: {
            stroke: '#d6d6d6',
            strokeLinecap: 'butt',
            transform: 'rotate(0.25turn)',
            transformOrigin: 'center center'
          },
          text: {
            fill: '#f88',
            fontSize: '16px'
          },
          background: {
            fill: '#3e98c7'
          }
        }}
      >
        <span className={styles.timeRemaining}>{remainingTime}</span>
        Working on
        <div className={styles.projectSelected}>{project}</div>
        {children}
      </CircularProgressbarWithChildren>
    </div>
  );
};

export default Timer;
