import React from 'react';
import { useSelector } from 'react-redux';
import PomodoroHistory from './PomodoroHistory';
import { getHistory } from './selectors';
import { getPreferenceBreakDuration } from '../Authentication/authenticationSelectors';

const PomodoroHistoryContainer = () => {
  const history = useSelector(getHistory);
  const breakDuration = useSelector(getPreferenceBreakDuration);
  return (
    <PomodoroHistory
      breakDuration={breakDuration}
      pomodoros={history.pomodoros}
      totalTime={history.total_time}
    ></PomodoroHistory>
  );
};

export default PomodoroHistoryContainer;
