import React from 'react';
import NavBar from '../NavBar';
import { useSelector } from 'react-redux';
import { getUsername } from '../../features/Authentication/authenticationSelectors';

const getUsernameFromEmail = email => {
  const isEmail = email && email.includes('@');
  if (isEmail) {
    return email.split('@')[0];
  }
};

const NavBarContainer = props => {
  const mail = useSelector(getUsername);
  const username = getUsernameFromEmail(mail);
  return <NavBar {...props} username={username} />;
};

export default NavBarContainer;
