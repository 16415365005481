import React, { useEffect, useRef } from 'react';
import CalendarHeatMap from 'react-calendar-heatmap';
import PropTypes from 'prop-types';
import './style.css';

const SessionsHeatmap = ({ data, max }) => {
  const maxQuarter = max / 4;
  let count = null;

  const today = new Date()
  const startDate = new Date()
  startDate.setDate(startDate.getDate() - 365)

  const colorFilter = value => {
    if (!value) {
      return 'color-empty';
    }
    count = value.count;
    if (count <= maxQuarter * 2) {
      if (count < maxQuarter) {
        return `color-scale-${1}`;
      } else {
        return `color-scale-${2}`;
      }
    } else {
      if (count > maxQuarter * 3) {
        return `color-scale-${4}`;
      } else {
        return `color-scale-${3}`;
      }
    }
  };

  return (
    <div id="test">
      <CalendarHeatMap
        startDate={startDate}
        endDate={today}
        values={data}
        showWeekdayLabels
        titleForValue={value => {
          const count = value ? value.count : 0;
          return `${count} sessions completed`;
        }}
        classForValue={colorFilter}
      ></CalendarHeatMap>
    </div>
  );
};

SessionsHeatmap.propTypes = {
  max: PropTypes.number.isRequired,
  data: PropTypes.array.isRequired
};

export default SessionsHeatmap;
