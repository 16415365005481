import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Modal, Form, Button, Message, Popup, Input } from 'semantic-ui-react';
import DropdownInput from '../../../components/DropdownInput';
import { useForm, useValidateForm } from '../../../hooks/formHooks';
import { timeHelper } from '../../../utils/timeHelper';

const initForm = project => ({
  name: project.name || '',
  pomodoro_duration: project.pomodoro_duration || ''
});

const isValid = form => form.name && form.pomodoro_duration;

const ProjectForm = ({
  project,
  saveProject,
  onCancel,
  saveButtonLabel,
  projectName,
  onProjectNameChange
}) => {
  // use state
  const [warningVisible, setWarningVisible] = useState(false);
  const [isNameFocus, setIsNameFocus] = useState(false);

  // use hooks
  const [form, handleChange, setForm] = useForm(initForm(project || {}));
  const [isSubmitted, submit] = useValidateForm();

  // use effects
  useEffect(() => {
    setForm(initForm(project || {}));
  }, [project, setForm]);

  // Functions
  const onSave = () => {
    submit();
    if (projectName.isFetching) {
      return;
    }
    if (
      isValid(form) &&
      projectName.isAvailable &&
      form.name.trim() === projectName.value.trim()
    ) {
      saveProject(form);
    } else {
      setWarningVisible(true);
    }
  };

  const handleProjectNameChange = e => {
    const name = e.target.value;
    setForm({ ...form, name });
    onProjectNameChange(name.trim());
  };

  return (
    <>
      <Modal.Content>
        <h2>Edit project</h2>
        {warningVisible && (
          <Message negative header="You must fill all the required fields!" />
        )}
        <Form>
          <Form.Field className="form-ph-input">
            <label>Name</label>
            <Popup
              className={
                projectName.isAvailable ? 'pop-up-success' : 'pop-up-error'
              }
              trigger={
                <Input
                  fluid
                  name="name"
                  placeholder="Name"
                  error={
                    (form.name === '' && isSubmitted) ||
                    !projectName.isAvailable
                  }
                  onFocus={() => setIsNameFocus(true)}
                  onBlur={() => setIsNameFocus(false)}
                  value={form.name}
                  onChange={handleProjectNameChange}
                  loading={projectName.isFetching}
                  icon={
                    projectName.isAvailable
                      ? 'thumbs up outline'
                      : 'thumbs down outline'
                  }
                />
              }
              open={
                (isNameFocus &&
                  projectName.value !== '' &&
                  !projectName.isFetching) ||
                !projectName.isAvailable
              }
              position="bottom center"
              color="red"
            >
              {projectName.isAvailable
                ? `${projectName.value} is valid`
                : `The project ${projectName.value} already exists on this account.
                `}
            </Popup>
          </Form.Field>
          <Form.Field>
            <label>Pomodoro duration</label>
            <DropdownInput
              className="form-ph-dropdown"
              name="pomodoro_duration"
              label="Pomodoro duration"
              placeholder="Pomodoro duration"
              error={form.pomodoro_duration === '' && isSubmitted}
              value={form.pomodoro_duration}
              onChange={handleChange}
              options={timeHelper.pomodorosDurationOptions}
            ></DropdownInput>
          </Form.Field>
        </Form>
      </Modal.Content>
      <Modal.Actions>
        <Button negative onClick={onCancel}>
          Cancel
        </Button>
        <Button positive onClick={onSave} disabled={!projectName.isAvailable}>
          {saveButtonLabel}
        </Button>
      </Modal.Actions>
    </>
  );
};

ProjectForm.propTypes = {
  project: PropTypes.object,
  saveProject: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
  saveButtonLabel: PropTypes.string.isRequired,
  projectName: PropTypes.shape({
    isFetching: PropTypes.bool.isRequired,
    value: PropTypes.string.isRequired,
    isAvailable: PropTypes.bool.isRequired
  }).isRequired,
  onProjectNameChange: PropTypes.func.isRequired
};

export default ProjectForm;
