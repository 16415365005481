import React, { useEffect } from 'react';
import { Form, Button } from 'semantic-ui-react';
import DropdownInput from '../../components/DropdownInput';
import { useForm } from '../../hooks/formHooks';
import { timeHelper } from '../../utils/timeHelper';

const SettingsForm = ({ onSave, defaultForm }) => {
  const [form, handleChange, setForm] = useForm(defaultForm);

  useEffect(() => {
    setForm(defaultForm);
  }, [defaultForm, setForm]);

  return (
    <Form>
      <Form.Field>
        <label>Break duration</label>
        <DropdownInput
          name="break_duration"
          label="Break duration"
          placeholder="Break duration"
          value={form.break_duration}
          onChange={handleChange}
          options={timeHelper.breaksDurationOptions}
        ></DropdownInput>
      </Form.Field>
      <Button positive onClick={() => onSave(form)}>
        Save
      </Button>
    </Form>
  );
};

export default SettingsForm;
