import React, { useEffect } from 'react';
import { Auth } from '../../../agent';
import { useToasts } from 'react-toast-notifications';
import { useHistory, useParams } from 'react-router-dom';
import ResetPassword from './ResetPassword';
import { useDispatch } from 'react-redux';
import { validateRecoverCodeFailure, resetPasswordFailure } from './actions';

const ResetPasswordContainer = () => {
  const { addToast } = useToasts();
  const dispatch = useDispatch();
  const history = useHistory();
  const params = useParams();

  useEffect(() => {
    Auth.validateRecoverCode(params.recoverCode)
      .then()
      .catch(e => {
        dispatch(validateRecoverCodeFailure(e));
        history.push('/recover-password');
      });
  }, [dispatch, history, params.recoverCode]);

  const onSaveNewPassword = newPassword => {
    Auth.resetPassword(params.recoverCode, newPassword)
      .then(_ => {
        addToast('Password changed', {
          appearance: 'success',
          autoDismiss: true
        });
        history.push('/login');
      })
      .catch(e => dispatch(resetPasswordFailure(e)));
  };

  return <ResetPassword onSaveNewPassword={onSaveNewPassword} />;
};

export default ResetPasswordContainer;
