import { Auth } from '../../../agent';
import { getLastPomodoro } from '../MainScreen/actions';

export const LOGIN = 'LOGIN';
export const LOGIN_SUCCESS = 'LOGIN_SUCCESS';
export const LOGIN_FAILURE = 'LOGIN_FAILURE';
export const LOGIN_START = 'LOGIN_START';
export const AUTHENTICATED_SUCCESS = 'AUTHENTICATED_SUCCESS';
export const LOGOUT = 'LOGOUT';
export const FORCE_LOGOUT = 'FORCE_LOGOUT';
export const LOADING = 'LOADING';
export const STOP_LOADING = 'STOP_LOADING';
export const LOADING_COMPLETE = 'LOADING_COMPLETE';

export const loading = () => ({
  type: LOADING
});

export const stopLoading = () => ({
  type: STOP_LOADING
});

export const loadingComplete = () => ({
  type: LOADING_COMPLETE
});

export const loginSuccess = payload => ({
  type: LOGIN_SUCCESS,
  payload
});

export const loginFailure = payload => ({
  type: LOGIN_FAILURE,
  payload
});

export const loginStart = payload => ({
  type: LOGIN_START,
  payload
});

const authenticate = (cookies, user, settings) => {
  Auth.configCookies(cookies);
  Auth.configHeaders();
  Auth.saveUserInfoLocalStorage(user);
  Auth.saveSettingsLocalStorage(settings);
};

export const signUp = (credentials, callback) => {
  return dispatch => {
    dispatch(loginStart());
    Auth.signUp(credentials)
      .then(r => {
        const data = r.data;
        const { cookies, user, settings } = data;
        authenticate(cookies, user, settings);
        callback && callback();
        dispatch(loginSuccess(data));
      })
      .catch(e => dispatch(loginFailure(e)));
  };
};

export const login = credentials => {
  return dispatch => {
    dispatch(loginStart());
    Auth.login(credentials)
      .then(r => {
        const data = r.data;
        const { cookies, user, settings } = data;
        authenticate(cookies, user, settings);
        dispatch(loginSuccess(data));
        dispatch(getLastPomodoro());
      })
      .catch(e => dispatch(loginFailure(e)));
  };
};

export const authenticatedSuccess = payload => {
  return dispatch => {
    const user = Auth.getUserInfoLocalStorage();
    const settings = Auth.getSettingsLocalStorage();
    dispatch(getLastPomodoro());
    dispatch({
      type: AUTHENTICATED_SUCCESS,
      payload: {
        user,
        settings
      }
    });
  };
};

export const logout = payload => {
  return dispatch => {
    Auth.deleteCookies();
    Auth.deleteAuthHeaders();
    dispatch({
      type: LOGOUT,
      payload
    });
  };
};

export const forceLogout = payload => {
  return dispatch => {
    Auth.deleteCookies();
    Auth.deleteAuthHeaders();
    dispatch({
      type: FORCE_LOGOUT,
      payload
    });
  };
};

export const RECOVER_PASSWORD_FAILURE = 'RECOVER_PASSWORD_FAILURE';

export const recoverPasswordFailure = payload => {
  return { type: RECOVER_PASSWORD_FAILURE, payload };
};

export const VALIDATE_RECOVER_CODE_FAILURE = 'VALIDATE_RECOVER_CODE_FAILURE';

export const validateRecoverCodeFailure = payload => {
  return { type: VALIDATE_RECOVER_CODE_FAILURE, payload };
};

export const RESET_PASSWORD_FAILURE = 'RESET_PASSWORD_FAILURE';

export const resetPasswordFailure = payload => {
  return { type: RESET_PASSWORD_FAILURE, payload };
};
