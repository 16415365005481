import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import SettingsForm from './SettingsForm';
import { getBreakDuration, getSettingsId } from './selectors';
import { saveSettings, loadSettings } from './actions';
import { useToasts } from 'react-toast-notifications';

const SettingsFormContainer = () => {
  const { addToast } = useToasts();
  const dispatch = useDispatch();
  const breakTime = useSelector(getBreakDuration);
  const settingsId = useSelector(getSettingsId);
  const defaultForm = {
    break_duration: breakTime
  };

  useEffect(() => {
    dispatch(loadSettings());
  }, [dispatch]);

  return (
    <SettingsForm
      defaultForm={defaultForm}
      onSave={settings => {
        dispatch(
          saveSettings(settings, settingsId, () => {
            addToast('Settings saved', {
              appearance: 'success',
              autoDismiss: true
            });
          })
        );
      }}
    ></SettingsForm>
  );
};

export default SettingsFormContainer;
