import {
  LOGIN_SUCCESS,
  AUTHENTICATED_SUCCESS,
  FORCE_LOGOUT,
  LOGOUT,
  LOADING,
  LOADING_COMPLETE,
  STOP_LOADING
} from './actions';
import {
  SAVE_SETTINGS_SUCCESS,
  LOAD_SETTINGS_SUCCESS
} from '../Settings/actions';

const initialState = {
  isAuthenticated: false,
  loader: {
    loaderIsVisible: false,
    loaderIsComplete: true
  },
  user: {}
};

const authenticationReducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case LOGIN_SUCCESS: {
      const { settings, user } = payload;
      return {
        ...state,
        settings,
        user,
        isAuthenticated: true
      };
    }
    case AUTHENTICATED_SUCCESS: {
      const { settings, user } = payload;
      return {
        ...state,
        settings,
        user,
        isAuthenticated: true
      };
    }
    case FORCE_LOGOUT:
      return { ...state, isAuthenticated: false };
    case LOGOUT:
      return { ...state, isAuthenticated: false };
    case LOADING:
      return {
        ...state,
        loader: { loaderIsVisible: true, loaderIsComplete: false }
      };
    case STOP_LOADING:
      return {
        ...state,
        loader: { loaderIsVisible: false, loaderIsComplete: false }
      };
    case LOADING_COMPLETE:
      return {
        ...state,
        loader: { loaderIsVisible: false, loaderIsComplete: true }
      };
    default:
      return state;
    case SAVE_SETTINGS_SUCCESS:
      return { ...state, settings: { ...payload } };
    case LOAD_SETTINGS_SUCCESS:
      return { ...state, settings: { ...payload } };
  }
};

export default authenticationReducer;
