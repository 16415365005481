import React from 'react';
import { Redirect, Route } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { Auth } from '../../agent';
import { authenticatedSuccess } from '../features/Authentication/actions';
import { getIsAuthenticated } from '../features/Authentication/authenticationSelectors';
import App from '../../App';

export const PrivateRoute = props => {
  const dispatch = useDispatch();
  const isAuthenticated = useSelector(getIsAuthenticated);
  let componentToRender = (
    <>
      <App />
      <Route {...props} />
    </>
  );
  if (!isAuthenticated) {
    if (!Auth.hasCookies() && !isAuthenticated) {
      componentToRender = <Redirect to="/signup" />;
    } else {
      Auth.configHeaders();
      dispatch(authenticatedSuccess());
    }
  }
  return componentToRender;
};

export default PrivateRoute;
