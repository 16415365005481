import { useToasts } from 'react-toast-notifications';

export const useAlert = () => {
  const { addToast } = useToasts();
  return {
    showWarning(message) {
      addToast(message, {
        appearance: 'warning',
        autoDismiss: true
      });
    }
  };
};
