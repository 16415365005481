import React from 'react';
import * as styles from './styles.module.css';
import { Button } from 'semantic-ui-react';

const StartWork = ({ onStart }) => {
  return (
    <>
      <Button className={styles.button} onClick={e => onStart()}>
        Let's work!
      </Button>
    </>
  );
};

export default StartWork;
