import {
  START_POMODORO,
  CANCEL_POMODORO,
  START_BREAK,
  CANCEL_BREAK,
  SAVE_BREAK,
  CHANGE_POMODORO_DURATION,
  SAVE_POMODORO_SUCCESS,
  SAVE_POMODORO_START,
  SELECT_POMODORO_PROJECT,
  ACKNOWLEDGE_TIMER_FINISHED,
  FETCH_HISTORY_SUCCESS,
  SAVE_BREAK_SUCCESS,
  PREPARE_TIMER_FOR_BREAK,
  PREPARE_TIMER_FOR_POMODORO,
  GET_LAST_POMODORO_SUCCESS,
  LOAD_POMODORO,
  SHOW_CONFIRM_CANCEL_SESSION
} from './actions';
import {
  DELETE_PROJECT_SUCCESS,
  FETCH_PROJECTS_SUCCESS
} from '../Projects/actions';

const initialState = {
  status: 'awaiting_pomodoro',
  alertOpen: false,
  projects: [],
  pomodoro: {
    id: null,
    start: null,
    duration: 1500,
    saved: false,
    project: null
  },
  break: {
    duration: 300
  },
  timer: {
    duration: 1500,
    start: null,
    finished: false,
    showConfirmCancel: false
  },
  history: {
    pomodoros: [],
    totalTime: -1
  }
};

export default (state = initialState, { type, payload }) => {
  switch (type) {
    case GET_LAST_POMODORO_SUCCESS: {
      if (Object.keys(payload).length > 0 && payload.constructor === Object) {
        const { duration, project, date, finished, id } = payload;
        const pomodoro = {
          ...state.pomodoro,
          duration,
          start: new Date(date),
          id
        };
        project && (pomodoro.project = project.id);
        const timer = { ...state.timer, duration };

        let status;
        if (!finished) {
          status = 'pomodoro_started';
          timer.finished = false;
          timer.start = new Date(date);
        }

        return {
          ...state,
          status: status || state.status,
          pomodoro: { ...pomodoro },
          timer: { ...state.timer, ...timer }
        };
      }
      return state;
    }
    case START_POMODORO:
      const { id, startDate } = payload;
      return {
        ...state,
        ...payload,
        pomodoro: { ...state.pomodoro, id, start: new Date(startDate) },
        timer: {
          ...state.timer,
          duration: state.pomodoro.duration,
          start: new Date(startDate)
        },
        status: 'pomodoro_started'
      };
    case PREPARE_TIMER_FOR_POMODORO:
      return {
        ...state,
        timer: { ...state.timer, duration: state.pomodoro.duration }
      };
    case SHOW_CONFIRM_CANCEL_SESSION:
      return {
        ...state,
        timer: { ...state.timer, showConfirmCancel: payload }
      };
    case CANCEL_POMODORO:
      return {
        ...state,
        timer: { ...state.timer, start: null, end: null },
        pomodoro: { ...state.pomodoro, start: null },
        status: 'awaiting_pomodoro'
      };
    case START_BREAK:
      state.audio && state.audio.pause();
      return {
        ...state,
        status: 'break_started',
        timer: {
          ...state.timer,
          start: new Date()
        }
      };
    case PREPARE_TIMER_FOR_BREAK:
      return {
        ...state,
        timer: {
          ...state.timer,
          duration: payload.breakDuration
        }
      };
    case CANCEL_BREAK:
      state.audio && state.audio.pause();
      return {
        ...state,
        timer: {
          ...state.timer,
          duration: state.pomodoro.duration,
          start: null
        },
        status: 'awaiting_pomodoro'
      };
    case SAVE_BREAK:
      if (state.timer.end) {
        return { ...state };
      }
      return {
        ...state,
        status: 'awaiting_pomodoro',
        timer: { ...state.timer, finished: true },
        alertOpen: true
      };
    case SAVE_BREAK_SUCCESS:
      return {
        ...state,
        timer: { ...state.timer, start: null, end: null },
        break: { ...state.break, count: ++state.break.count }
      };
    case SAVE_POMODORO_START:
      if (state.end) {
        return { ...state };
      }
      return {
        ...state,
        alertOpen: true,
        status: 'awaiting_break',
        timer: { ...state.timer, finished: true },
        pomodoro: { ...state.pomodoro, saved: true }
      };
    case SAVE_POMODORO_SUCCESS:
      return {
        ...state,
        timer: {
          ...state.timer,
          start: null,
          end: null,
          showConfirmCancel: false
        }
      };
    case FETCH_PROJECTS_SUCCESS:
      return {
        ...state,
        projects: payload
      };
    case SELECT_POMODORO_PROJECT:
      const stateCopy = {
        ...state,
        pomodoro: {
          ...state.pomodoro,
          project: payload.id,
          duration: payload.pomodoro_duration
        }
      };
      if (stateCopy.status === 'awaiting_pomodoro') {
        stateCopy.timer.duration = payload.pomodoro_duration;
      }
      return stateCopy;
    case CHANGE_POMODORO_DURATION:
      if (state.status === 'break_started') {
        return {
          ...state,
          pomodoro: { ...state.pomodoro, duration: payload }
        };
      }
      return {
        ...state,
        timer: { ...state.timer, duration: payload },
        pomodoro: { ...state.pomodoro, duration: payload }
      };
    case ACKNOWLEDGE_TIMER_FINISHED:
      return {
        ...state,
        alertOpen: false,
        timer: { ...state.timer, finished: false },
        pomodoro: { ...state.pomodoro, saved: false, start: null }
      };
    case FETCH_HISTORY_SUCCESS:
      return { ...state, history: payload };
    case DELETE_PROJECT_SUCCESS: {
      const projectSelected = state.pomodoro.project;
      if (payload === projectSelected) {
        const pomodoro = { ...state.pomodoro };
        delete pomodoro.project;
        return {
          ...state,
          pomodoro
        };
      }
      return state;
    }
    case LOAD_POMODORO: {
      const pomodoro = payload;
      return {
        ...state,
        status: 'pomodoro_started',
        pomodoro: {
          ...state.pomodoro,
          id: pomodoro.id,
          duration: pomodoro.duration,
          project: pomodoro.project.id,
          start: new Date(pomodoro.date)
        },
        timer: {
          ...state.timer,
          duration: pomodoro.duration,
          start: new Date(pomodoro.date)
        }
      };
    }
    default:
      return state;
  }
};
