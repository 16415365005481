import * as firebase from 'firebase/app';
import 'firebase/auth';
import 'firebase/firestore';
import 'firebase/analytics';
import 'firebase/messaging';
import { Devices } from '../../agent';

class Firebase {
  constructor() {
    const firebaseConfig = {
      apiKey: 'AIzaSyD6dTtuXJ3kwtlmoI-wrSUmV159BgFHlLk',
      authDomain: 'productivity-hub-4a61a.firebaseapp.com',
      databaseURL: 'https://productivity-hub-4a61a.firebaseio.com',
      projectId: 'productivity-hub-4a61a',
      storageBucket: 'productivity-hub-4a61a.appspot.com',
      messagingSenderId: '153927045990',
      appId: '1:153927045990:web:43823740734480cee7aea7',
      measurementId: 'G-PN9E5W3WDG'
    };
    firebase.initializeApp(firebaseConfig);
    firebase.analytics();
    this.messaging = firebase.messaging();
    this.messaging.usePublicVapidKey(
      'BILrZCX8bO69keZmqj8fc0eW_1ZtTLXbmzLi9Yf58dn-8jeIn7kl5xuH4-YPeYsSYabPn0h6TpGnEkeuPuGxdDw'
    );
    this.messaging.onTokenRefresh(() => {
      this.getToken();
    });
  }

  getToken = success => {
    this.messaging
      .getToken()
      .then(currentToken => {
        success && success(currentToken);
        if (currentToken) {
          Devices.create({ firebase_token: currentToken });
        }
      })
      .catch(e => {
        console.error('An error occurred while retrieving token. ', e);
      });
    this.messaging.onMessage(payload => {
      try {
        const { title, body } = payload.notification;
        if (document.hasFocus() === false) {
          if (Notification.permission === 'granted') {
            navigator.serviceWorker.getRegistration().then(function (reg) {
              reg && reg.showNotification(title, { body });
            });
          }
        }
      } catch (e) {
        console.log(e);
      }
    });
  };
}

let instance = null;

export const initInstance = () => {
  const notificationPermissionGranted = Notification.permission === 'granted';
  const isNotTestEnv = process.env.NODE_ENV !== 'test';
  if (isNotTestEnv && instance === null && notificationPermissionGranted) {
    instance = new Firebase();
    Object.freeze(instance);
  }
  return instance;
};

export default instance;
