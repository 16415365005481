import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import moment from 'moment';
import { timeHelper } from '../../../utils/timeHelper';
import { savePomodoro, saveBreak } from '../actions';
import {
  getTimer,
  getPomodoroStatus,
  getPomodoroObject,
  getProjects
} from '../selectors';
import Timer from '../Timer';

const TimerContainer = props => {
  const dispatch = useDispatch();
  const MILLISECONDS = 1000;

  const pomodoroStatus = useSelector(getPomodoroStatus);
  const pomodoro = useSelector(getPomodoroObject);
  const projects = useSelector(getProjects);
  const timer = useSelector(getTimer);
  const { start, duration } = timer;

  const [count, setCount] = useState(
    moment.duration(timer.duration * MILLISECONDS)
  );

  const [refresh, setRefresh] = useState(null);

  let projectName = '';
  const projectSelected = projects.find(
    project => project.id === pomodoro.project
  );
  if (projectSelected) {
    projectName = projectSelected.name;
  }

  const diffNowAndStart = startTime =>
    moment.duration(moment().diff(moment(startTime)));

  /**
   *
   * @param {Duration} timeRemaining time remaning
   * @param {Number} duration pomodoros duration
   */
  const shouldKeepTicking = (timeRemaining, duration) => {
    return timeRemaining.asMilliseconds() < duration * MILLISECONDS;
  };

  const ticToc = () => {
    let timeout;
    if (start) {
      const timeRemaining = diffNowAndStart(start);
      const pomodoroRemaining = timeHelper.durationDiff(
        moment.duration(duration * 1000),
        timeRemaining
      );
      setCount(pomodoroRemaining);

      if (shouldKeepTicking(timeRemaining, duration)) {
        timeout = setTimeout(() => {
          setRefresh(new Date());
        }, MILLISECONDS);
      }
    }
    return () => clearTimeout(timeout);
  };

  useEffect(ticToc, [start, duration, refresh]);

  useEffect(() => {
    const timeRemaining = diffNowAndStart(timer.start);
    if (
      !shouldKeepTicking(timeRemaining, timer.duration) &&
      !timer.end &&
      timer.start
    ) {
      switch (pomodoroStatus) {
        case 'pomodoro_started':
          dispatch(savePomodoro(pomodoro));
          break;
        case 'break_started':
          const breakObject = { ...timer, project: pomodoro.project };
          dispatch(saveBreak(breakObject));
          break;
        default:
          break;
      }
      setRefresh(null);
    } else if (
      !timer.end &&
      !timer.start &&
      count.asSeconds() !== timer.duration
    ) {
      setCount(moment.duration(timer.duration * MILLISECONDS));
    }
  }, [count, pomodoro, timer, pomodoroStatus, dispatch]);

  return (
    <Timer
      {...props}
      remainingTime={timeHelper.secondsToMinutes(count)}
      percentage={count.asMilliseconds() / (timer.duration * MILLISECONDS)}
      projectSelected={projectName}
    />
  );
};

export default TimerContainer;
