import React from 'react';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import ProjectForm from './ProjectForm';
import { saveProject } from './actions';
import { useToasts } from 'react-toast-notifications';

const CreateProjectContainer = props => {
  const history = useHistory();
  const dispatch = useDispatch();
  const { addToast } = useToasts();
  return (
    <ProjectForm
      {...props}
      saveProject={project =>
        dispatch(
          saveProject(project, history, () => {
            addToast('Project created', {
              appearance: 'success',
              autoDismiss: true
            });
          })
        )
      }
      saveButtonLabel="Create project"
      onCancel={() => history.goBack()}
    ></ProjectForm>
  );
};

export default CreateProjectContainer;
