import moment from 'moment';

const secondsToMinutes = count => {
  if (!count) {
    return 'Loading...';
  }
  const time = count.asSeconds();
  let [minutes, seconds] = String(time / 60).split('.');
  seconds = Math.floor(parseFloat('.' + seconds) * 60);
  let secondsString = '00';
  if (seconds && seconds !== 0) {
    secondsString = seconds < 10 ? `0${String(seconds)}` : String(seconds);
  }
  return `${minutes}:${secondsString}`;
};

export const timeHelper = (() => ({
  secondsToMinutes,
  secondsToMiliseconds: seconds => seconds * 1000,
  minutesToMiliseconds: minutes => minutes * 1000 * 60,
  displayDateTimeForHumans: dateTime => moment(dateTime).fromNow(),
  displayTimeForHuman: seconds => {
    let format = null,
      firstValue = null,
      secondValue = null;
    if (seconds > 86400) {
      format = 'DD:HH';
      firstValue = 'D';
      secondValue = 'H';
    } else {
      format = 'HH:mm';
      firstValue = 'H';
      secondValue = 'M';
    }
    let time = moment
      .utc(seconds * 1000)
      .format(format)
      .split(':');
    return `${time[0]}${firstValue}:${time[1]}${secondValue}`;
  },
  durationDiff: (durationObject1, durationObject2) =>
    moment.duration(
      durationObject1.asMilliseconds() - durationObject2.asMilliseconds()
    ),
  pomodorosDurationOptions: (() => {
    let duration = [
      { id: 300, name: '5 minutes' },
      { id: 600, name: '10 minutes' },
      { id: 900, name: '15 minutes' },
      { id: 1200, name: '20 minutes' },
      { id: 1500, name: '25 minutes' },
      { id: 1800, name: '30 minutes' },
      { id: 2100, name: '35 minutes' },
      { id: 2400, name: '40 minutes' },
      { id: 2700, name: '45 minutes' },
      { id: 3000, name: '50 minutes' },
      { id: 3300, name: '55 minutes' },
      { id: 3600, name: '60 minutes' },
      { id: 4500, name: '75 minutes' },
      { id: 5400, name: '90 minutes' },
      { id: 7200, name: '120 minutes' },
      { id: 9000, name: '150 minutes' },
      { id: 10800, name: '180 minutes' }
    ];
    if (process.env.NODE_ENV === 'development') {
      duration = duration.concat([
        { id: 5, name: '5 seconds' },
        { id: 60, name: '1 minutes' },
        { id: 120, name: '2 minutes' }
      ]);
    }
    return duration;
  })(),
  breaksDurationOptions: (() => [
    { id: 60, name: '1 minutes' },
    { id: 120, name: '2 minutes' },
    { id: 180, name: '3 minutes' },
    { id: 240, name: '4 minutes' },
    { id: 300, name: '5 minutes' },
    { id: 360, name: '6 minutes' },
    { id: 420, name: '7 minutes' },
    { id: 480, name: '8 minutes' },
    { id: 540, name: '9 minutes' },
    { id: 600, name: '10 minutes' },
    { id: 900, name: '15 minutes' }
  ])()
}))();
