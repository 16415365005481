import { createStore, combineReducers, applyMiddleware, compose } from 'redux';
import thunk from 'redux-thunk';
import projectReducer from './common/features/Projects/projectReducer';
import pomodoroReducer from './common/features/MainScreen/pomodoroReducer';
import authenticationReducer from './common/features/Authentication/authenticationReducer';
import ErrorHandlingReducer from './common/features/ErrorHandling/ErrorHandlingReducer';

export default function configureStore(initialState) {
  const store = createStore(
    combineReducers({
      project: projectReducer,
      pomodoro: pomodoroReducer,
      authentication: authenticationReducer,
      errorHandling: ErrorHandlingReducer
    }),
    initialState,
    compose(
      applyMiddleware(thunk),
      window.__REDUX_DEVTOOLS_EXTENSION__
        ? window.__REDUX_DEVTOOLS_EXTENSION__()
        : a => a
    )
  );
  return store;
}
