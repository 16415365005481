import { Projects } from '../../../agent';
import { stopLoading, loading } from '../Authentication/actions';
import { selectPomodoroProject } from '../MainScreen/actions';
/**
 * Project
 */
export const FETCH_PROJECT_START = 'FETCH_PROJECT_START';
export const FETCH_PROJECT_SUCCESS = 'FETCH_PROJECT_SUCCESS';
export const FETCH_PROJECT_FAILURE = 'FETCH_PROJECT_FAILURE';

export const fetchProjectStart = payload => ({
  type: FETCH_PROJECT_START,
  payload
});

export const fetchProjectSuccess = payload => ({
  type: FETCH_PROJECT_SUCCESS,
  payload
});

export const fetchProjectFailure = payload => ({
  type: FETCH_PROJECT_FAILURE,
  payload
});

export const fetchProject = projectId => {
  return dispatch => {
    dispatch(loading());
    dispatch(fetchProjectStart(projectId));
    Projects.get(projectId)
      .then(r => dispatch(fetchProjectSuccess(r.data)))
      .catch(e => dispatch(fetchProjectFailure(e)))
      .finally(() => dispatch(stopLoading()));
  };
};

/**
 * Projects
 */
export const FETCH_PROJECTS_START = 'FETCH_PROJECTS_START';
export const FETCH_PROJECTS_SUCCESS = 'FETCH_PROJECTS_SUCCESS';
export const FETCH_PROJECTS_FAILURE = 'FETCH_PROJECTS_FAILURE';

export const fetchProjectsStart = payload => ({
  type: FETCH_PROJECTS_START,
  payload
});

export const fetchProjectsSuccess = payload => ({
  type: FETCH_PROJECTS_SUCCESS,
  payload
});

export const fetchProjectsFailure = payload => ({
  type: FETCH_PROJECTS_FAILURE,
  payload
});

export const fetchProjects = callback => {
  return dispatch => {
    dispatch(fetchProjectsStart());
    Projects.all
      .default()
      .then(r => {
        const projects = r.data;
        const projectsEmpty = projects.length === 0;
        if (projectsEmpty) {
          callback && callback();
        }
        if (projects.length === 1) {
          const project = projects[0];
          if (project.duration === 0) {
            dispatch(selectPomodoroProject(project));
          }
        }
        dispatch(fetchProjectsSuccess(r.data));
      })
      .catch(e => dispatch(fetchProjectsFailure(e)));
  };
};

/**
 * Projects
 */

/**
 * Save project
 */

export const SAVE_PROJECT_START = 'SAVE_PROJECT_START';
export const SAVE_PROJECT_SUCCESS = 'SAVE_PROJECT_SUCCESS';
export const SAVE_PROJECT_FAILURE = 'SAVE_PROJECT_FAILURE';

export const saveProjectStart = payload => ({
  type: SAVE_PROJECT_START,
  payload
});

export const saveProjectSuccess = payload => ({
  type: SAVE_PROJECT_SUCCESS,
  payload
});

export const saveProjectFailure = payload => ({
  type: SAVE_PROJECT_FAILURE,
  payload
});

export const saveProject = (project, successCallback) => {
  return dispatch => {
    dispatch(saveProjectStart(project));
    Projects.create(project)
      .then(r => {
        dispatch(saveProjectSuccess(r.data));
        successCallback && successCallback(r.data.id);
      })
      .catch(e => dispatch(saveProjectFailure(e)));
  };
};

/**
 * Update project
 */

export const UPDATE_PROJECT_START = 'UPDATE_PROJECT_START';
export const UPDATE_PROJECT_SUCCESS = 'UPDATE_PROJECT_SUCCESS';
export const UPDATE_PROJECT_FAILURE = 'UPDATE_PROJECT_FAILURE';

export const updateProjectStart = payload => ({
  type: UPDATE_PROJECT_START,
  payload
});

export const updateProjectSuccess = payload => ({
  type: UPDATE_PROJECT_SUCCESS,
  payload
});

export const updateProjectFailure = payload => ({
  type: UPDATE_PROJECT_FAILURE,
  payload
});

export const updateProject = (project, projectId, successCallback) => {
  return dispatch => {
    dispatch(updateProjectStart(project));
    Projects.update(project, projectId)
      .then(r => {
        dispatch(updateProjectSuccess(r.data));
        successCallback();
      })
      .catch(e => dispatch(updateProjectFailure(e)));
  };
};

/**
 * Delete project
 */

export const DELETE_PROJECT_START = 'DELETE_PROJECT_START';
export const DELETE_PROJECT_SUCCESS = 'DELETE_PROJECT_SUCCESS';
export const DELETE_PROJECT_FAILURE = 'DELETE_PROJECT_FAILURE';

export const deleteProjectStart = payload => ({
  type: DELETE_PROJECT_START,
  payload
});

export const deleteProjectSuccess = payload => ({
  type: DELETE_PROJECT_SUCCESS,
  payload
});

export const deleteProjectFailure = payload => ({
  type: DELETE_PROJECT_FAILURE,
  payload
});

export const deleteProject = (projectId, successCallback, failureCallback) => {
  return dispatch => {
    dispatch(deleteProjectStart(projectId));
    Projects.delete(projectId)
      .then(r => {
        dispatch(deleteProjectSuccess(projectId));
        successCallback && successCallback();
      })
      .catch(e => {
        dispatch(deleteProjectFailure(e));
        failureCallback && failureCallback();
      });
  };
};

/**
 * Sessions by project
 */

export const UPDATE_CURRENT_PAGE_SESSIONS_BY_PROJECT =
  'UPDATE_CURRENT_PAGE_SESSIONS_BY_PROJECT';
export const updateCurrentPageSessionsByProject = payload => ({
  type: UPDATE_CURRENT_PAGE_SESSIONS_BY_PROJECT,
  payload
});

export const CLEAN_SESSIONS_BY_PROJECT = 'CLEAN_SESSIONS_BY_PROJECT';

export const cleanSessionsByProject = payload => ({
  type: CLEAN_SESSIONS_BY_PROJECT,
  payload
});

export const FETCH_SESSIONS_BY_PROJECT_START =
  'FETCH_SESSIONS_BY_PROJECT_START';
export const FETCH_SESSIONS_BY_PROJECT_SUCCESS =
  'FETCH_SESSIONS_BY_PROJECT_SUCCESS';
export const FETCH_SESSIONS_BY_PROJECT_FAILURE =
  'FETCH_SESSIONS_BY_PROJECT_FAILURE';

export const fetchSessionsByProjectStart = payload => ({
  type: FETCH_SESSIONS_BY_PROJECT_START,
  payload
});

export const fetchSessionsByProjectSuccess = payload => ({
  type: FETCH_SESSIONS_BY_PROJECT_SUCCESS,
  payload
});

export const fetchSessionsByProjectFailure = payload => ({
  type: FETCH_SESSIONS_BY_PROJECT_FAILURE,
  payload
});

export const fetchSessionsByProject = (projectId, page) => {
  return dispatch => {
    dispatch(fetchSessionsByProjectStart({ projectId, page }));
    Projects.getSessions(projectId, page)
      .then(r => dispatch(fetchSessionsByProjectSuccess(r.data)))
      .catch(e => dispatch(fetchSessionsByProjectFailure(e)));
  };
};

/**
 * Sessions by project
 */
export const FETCH_SESSIONS_HEATMAP_BY_PROJECT_START =
  'FETCH_SESSIONS_HEATMAP_BY_PROJECT_START';
export const FETCH_SESSIONS_HEATMAP_BY_PROJECT_SUCCESS =
  'FETCH_SESSIONS_HEATMAP_BY_PROJECT_SUCCESS';
export const FETCH_SESSIONS_HEATMAP_BY_PROJECT_FAILURE =
  'FETCH_SESSIONS_HEATMAP_BY_PROJECT_FAILURE';

export const fetchSessionsHeatmapByProjectStart = payload => ({
  type: FETCH_SESSIONS_HEATMAP_BY_PROJECT_START,
  payload
});

export const fetchSessionsHeatmapByProjectSuccess = payload => ({
  type: FETCH_SESSIONS_HEATMAP_BY_PROJECT_SUCCESS,
  payload
});

export const fetchSessionsHeatmapByProjectFailure = payload => ({
  type: FETCH_SESSIONS_HEATMAP_BY_PROJECT_FAILURE,
  payload
});

export const fetchSessionsHeatmapByProject = projectId => {
  return dispatch => {
    dispatch(fetchSessionsHeatmapByProjectStart({ projectId }));
    Projects.getSessionsHeatmap(projectId)
      .then(r => dispatch(fetchSessionsHeatmapByProjectSuccess(r.data)))
      .catch(e => dispatch(fetchSessionsHeatmapByProjectFailure(e)));
  };
};

/**
 * Find if project name exists
 */
export const CHECK_PROJECT_NAME_START = 'CHECK_PROJECT_NAME_START';
export const CHECK_PROJECT_NAME_SUCCESS = 'CHECK_PROJECT_NAME_SUCCESS';
export const CHECK_PROJECT_NAME_FAILURE = 'CHECK_PROJECT_NAME_FAILURE';
export const CLEAR_CHECK_PROJECT_NAME = 'CLEAR_CHECK_PROJECT_NAME';

export const clearCheckProjectName = () => ({ type: CLEAR_CHECK_PROJECT_NAME });

export const checkProjectNameStart = payload => ({
  type: CHECK_PROJECT_NAME_START,
  payload
});

export const checkProjectNameSuccess = payload => ({
  type: CHECK_PROJECT_NAME_SUCCESS,
  payload
});

export const checkProjectNameFailure = payload => ({
  type: CHECK_PROJECT_NAME_FAILURE,
  payload
});

export const checkProjectName = (name, currentName) => {
  return dispatch => {
    dispatch(checkProjectNameStart({ name, currentName }));
    Projects.checkName(name, currentName)
      .then(r => dispatch(checkProjectNameSuccess(r.data)))
      .catch(e => dispatch(checkProjectNameFailure(e)));
  };
};
