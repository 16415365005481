import React from 'react';
import { useDispatch } from 'react-redux';
import { Icon } from 'semantic-ui-react';
import styles from './style.module.css';
import { logout } from '../../features/Authentication/actions';
import NavBarItem from '../NavBarItem';
import { useHistory } from 'react-router-dom';

export default props => {
  const history = useHistory();
  let location = history.location.pathname;
  const pushLocation = path => history.push(path);

  let { username } = props;
  let profilePicture =
    'https://laverdadnoticias.com/__export/1578275152493/sites/laverdad/img/2020/01/05/bezoss.jpg_1834093470.jpg';
  const dispatch = useDispatch();

  return (
    <>
      <div className={styles.hiddenMenu}></div>
      <div className={styles.navBarContainer}>
        <img
          className={styles.profilePicture}
          src={profilePicture}
          alt="profile user"
        />
        <div className={styles.username}>
          <h4>Hello {username}</h4>
        </div>
        <div>
          Your plan: <span className={styles.plan}>Free</span>
        </div>
        <div className={styles.menu}>
          <h4>Menu</h4>
          <NavBarItem
            path="/timer"
            title="Timer"
            icon="clock outline"
            location={location}
            pushLocation={pushLocation}
          ></NavBarItem>
          <NavBarItem
            path="/projects"
            title="Projects"
            icon="folder"
            location={location}
            pushLocation={pushLocation}
          ></NavBarItem>
          <NavBarItem
            path="/settings"
            icon="settings"
            title="Settings"
            location={location}
            pushLocation={pushLocation}
          ></NavBarItem>
          <div
            className={`${styles.logout}`}
            onClick={() => dispatch(logout())}
          >
            <Icon name="power" size="large" />
            Logout
          </div>
        </div>
      </div>
    </>
  );
};
