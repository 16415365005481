import React, { useState, useRef, useEffect } from 'react';
import { Input, Form, Button } from 'semantic-ui-react';
import { Link } from 'react-router-dom';
import * as styles from './styles.module.css';

const Login = ({ onSuccess }) => {
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const usernameInput = useRef(null);

  const login = () => onSuccess({ username: username.trim(), password });

  useEffect(() => {
    usernameInput.current.focus();
  }, []);

  return (
    <div className={styles.loginWrapper}>
      <div className={styles.login}>
        <Form onSubmit={login}>
          <h1 className={styles.title}>Productivity Hub</h1>
          <h2>Log in</h2>
          <p>
            Improve your work focusing on what matters using the pomodoro
            technique.
          </p>
          <Form.Field className="form-ph-input">
            <label>Email</label>
            <Input
              ref={usernameInput}
              name="email"
              type="email"
              onChange={e => setUsername(e.target.value)}
            ></Input>
          </Form.Field>
          <Form.Field className="form-ph-input">
            <label>Password</label>
            <Input
              name="password"
              type="password"
              onChange={e => setPassword(e.target.value)}
            />
            <div style={{ float: 'right' }}>
              <Link to="/recover-password">Forgot password?</Link>
            </div>
          </Form.Field>
          <div>
            <Button className={styles.loginButton} type="submit">
              Log In
            </Button>
          </div>
          <div className={styles.alternativeAction}>
            Don't you have an account?<Link to="/signup"> Sign up</Link>
          </div>
        </Form>
      </div>
    </div>
  );
};

export default Login;
