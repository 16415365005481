import React from 'react';
import RecoverPassword from './RecoverPassword';
import { Auth } from '../../../agent';
import { useDispatch } from 'react-redux';
import { recoverPasswordFailure } from './actions';
import { useToasts } from 'react-toast-notifications';

const RecoverPasswordContainer = ({ onSuccess }) => {
  const { addToast } = useToasts();
  const dispatch = useDispatch();
  const sendPassword = email =>
    Auth.recoverPassword(email)
      .then(r => {
        addToast('Password sent', {
          appearance: 'success',
          autoDismiss: true
        });
      })
      .catch(e => dispatch(recoverPasswordFailure(e)));

  return <RecoverPassword onSuccess={sendPassword} />;
};

export default RecoverPasswordContainer;
