import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { Grid } from 'semantic-ui-react';
import {
  getProject,
  getSessionsHeatmap,
  getSessionsMaxHeatmap
} from '../selectors';
import MainContainer from '../../../components/MainContainer';
import EditProjectModalContainer from './EditProjectModalContainer';
import { fetchProject, fetchSessionsHeatmapByProject } from '../actions';
import DeleteProjectContainer from './DeleteProjectContainer';
import SessionsHeatmap from './SessionsHeatmap';
import SessionsTableContainer from './SessionsTableContainer';
import StatsContainer from './StatsContainer';
import styles from './styles.module.css';

function ProjectPage() {
  // States
  const [isOpenEditModal, setOpenEditModal] = useState(false);

  // useHooks
  const dispatch = useDispatch();
  const params = useParams();
  const projectId = params.projectId;

  //Selectors
  const project = useSelector(getProject);
  const heatmapData = useSelector(getSessionsHeatmap);
  const heatmapMax = useSelector(getSessionsMaxHeatmap);

  // effects
  useEffect(() => {
    dispatch(fetchProject(projectId));
    dispatch(fetchSessionsHeatmapByProject(projectId));
  }, [dispatch, projectId]);

  return (
    <MainContainer title={project.name}>
      <Grid.Row>
        <Grid.Column floated="right" mobile={16}>
          <div className={styles.buttonsWrapper}>
            <EditProjectModalContainer
              isOpen={isOpenEditModal}
              open={() => setOpenEditModal(true)}
              close={() => setOpenEditModal(false)}
              projectId={projectId}
              project={project}
            />
            <DeleteProjectContainer project={project} />
          </div>
        </Grid.Column>
      </Grid.Row>
      <Grid.Row>
        <Grid.Column computer={8} tablet={8} mobile={16}>
          <Grid.Row>
            <StatsContainer projectId={projectId}></StatsContainer>
          </Grid.Row>
          <Grid.Row>
            <SessionsHeatmap data={heatmapData} max={heatmapMax} />
            Sessions completed in the year
          </Grid.Row>
        </Grid.Column>
        <Grid.Column computer={8} tablet={8} mobile={16}>
          <SessionsTableContainer projectId={projectId} />
        </Grid.Column>
      </Grid.Row>
    </MainContainer>
  );
}

export default ProjectPage;
