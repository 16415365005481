import React from 'react';
import PropTypes from 'prop-types';
import { Icon, Grid } from 'semantic-ui-react';
import styles from './styles.module.css';

const Statistic = ({ title, label, icon }) => {
  return (
    <Grid.Column>
      <div>
        <Icon className={styles.icon} name={icon} />
        <span className={styles.title}>{title}</span>
      </div>
      <div className={styles.label}>{label}</div>
    </Grid.Column>
  );
};

Statistic.propTypes = {
  title: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  icon: PropTypes.string
};

export default Statistic;
