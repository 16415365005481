import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import LoadingBar from 'react-top-loading-bar';
import { loadingComplete } from '../../features/Authentication/actions';

const getLoader = state => {
  return state.authentication.loader;
};

const Loader = () => {
  const dispatch = useDispatch();
  const [progress, setProgress] = useState(0);
  const { loaderIsVisible, loaderIsComplete } = useSelector(getLoader);
  useEffect(() => {
    if (loaderIsVisible && !loaderIsComplete) {
      setTimeout(() => {
        setProgress(30);
      }, 200);
    } else if (!loaderIsVisible && !loaderIsComplete) {
      setProgress(100);
      dispatch(loadingComplete());
    }
  }, [loaderIsVisible, setProgress, dispatch, loaderIsComplete]);
  return (
    <LoadingBar progress={progress} color="#4080b7" height={3} visible={true} />
  );
};

export default Loader;
