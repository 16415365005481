import React from 'react';

const Presentation = () => {
  return (
    <img
      alt="background"
      width="80%"
      src="https://productivityhub.work/images/signup.png"
    ></img>
  );
};

export default Presentation;
