import React from 'react';
import CancelModal from '../../../../components/ConfirmModal';

const DeleteProject = ({ modalVisible, warningLabel, close, onDelete }) => {
  return (
    <CancelModal
      modalVisible={modalVisible}
      header="Delete project"
      headerIcon="archive"
      content={warningLabel}
      onCancel={close}
      onConfirm={onDelete}
      confirmMessage="Delete project"
      cancelMessage="Cancel"
    />
  );
};

export default DeleteProject;
