import React from 'react';
import ReactDOM from 'react-dom';
import { init as initApm } from '@elastic/apm-rum';
import { Provider } from 'react-redux';
import { ToastProvider } from 'react-toast-notifications';
import './index.css';
import 'react-calendar-heatmap/dist/styles.css';
import 'semantic-ui-css/semantic.min.css';
import { unregister } from './serviceWorker';
import Router from './Router';
import configureStore from './store';

initApm({
  serviceName: 'productivity-hub-frontend',
  serverUrl: 'https://productivityhub.work/apm/',
  serviceVersion: '1'
});

const store = configureStore({});
ReactDOM.render(
  <Provider store={store}>
    <ToastProvider>
      <Router />
    </ToastProvider>
  </Provider>,
  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA

unregister();
