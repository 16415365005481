import React, { useEffect, useState } from 'react';
import MainContainer from '../../../components/MainContainer';
import { Grid, Button, Icon } from 'semantic-ui-react';
import { useSelector, useDispatch } from 'react-redux';
import { getProjects } from '../selectors';
import Projects from '../Projects';
import { fetchProjects } from '../actions';
import styles from './styles.module.css';
import CreateProjectModalContainer from './CreateProjectModalContainer';
import SearchProject from './SearchProject';

const ProjectsPage = () => {
  const projects = useSelector(getProjects);
  const [filteredProjects, setFilteredProjects] = useState([]);
  const dispatch = useDispatch();
  const [viewAsList, setViewAsList] = useState(false);
  const [createProjectOpen, setCreateProjectOpen] = useState(false);

  useEffect(() => {
    setFilteredProjects(projects);
  }, [projects]);

  useEffect(() => {
    dispatch(fetchProjects());
  }, [dispatch]);

  return (
    <MainContainer title="Projects">
      <CreateProjectModalContainer
        isOpen={createProjectOpen}
        close={() => setCreateProjectOpen(false)}
      ></CreateProjectModalContainer>
      <Grid.Row>
        <Grid.Column floated="right" mobile={16}>
          <div className={styles.viewTypeWrapper}>
            <Icon
              name="th"
              size="large"
              className={
                viewAsList ? styles.viewOption : styles.selectedViewOption
              }
              onClick={() => setViewAsList(false)}
            ></Icon>
            <Icon
              name="th list"
              className={
                viewAsList ? styles.selectedViewOption : styles.viewOption
              }
              size="large"
              onClick={() => setViewAsList(true)}
            ></Icon>
            <Button
              className={styles.createProjectButton}
              onClick={() => setCreateProjectOpen(true)}
            >
              Create a new project
            </Button>
          </div>
        </Grid.Column>
      </Grid.Row>
      <Grid.Row>
        <Grid stackable columns={4}>
          <SearchProject
            projects={projects}
            onApplyFilter={projects => setFilteredProjects(projects)}
          />
          <Projects
            viewAsList={viewAsList}
            projects={filteredProjects}
          ></Projects>
        </Grid>
      </Grid.Row>
    </MainContainer>
  );
};

export default ProjectsPage;
