import React, { useEffect, useState } from 'react';
import { Form, Button, Input, Message } from 'semantic-ui-react';

import { useForm, useValidateForm } from '../../../hooks/formHooks';
import DropdownInput from '../../../components/DropdownInput';
import { timeHelper } from '../../../utils/timeHelper';

const isValid = form => form.name && form.pomodoro_duration;

const AddProject = ({ project, onNext }) => {
  const [form, handleChange, setForm] = useForm({ ...project });
  const [isSubmitted, submit] = useValidateForm();
  const [warningVisible, setWarningVisible] = useState(false);

  useEffect(() => {
    setForm(project);
  }, [project, setForm]);

  const onSubmit = () => {
    submit();
    if (isValid(form)) {
      onNext({ ...form });
    } else {
      setWarningVisible(true);
    }
  };

  return (
    <Form>
      <h2>Add a project</h2>

      {warningVisible && (
        <Message negative header="You must fill all the required fields!" />
      )}
      <Form.Field
        error={isSubmitted && form.name === ''}
        className="form-ph-input"
      >
        <label>In what project are you going to work today?</label>
        <Input
          className="form-ph-dropdown"
          name="name"
          onChange={handleChange}
          placeholder="Chemestry, Office work, Tesis..."
          value={form.name}
        />
      </Form.Field>
      <Form.Field>
        <label>For how long will you be working?</label>
        <DropdownInput
          className="form-ph-dropdown"
          name="pomodoro_duration"
          placeholder="Select project duration"
          options={timeHelper.pomodorosDurationOptions}
          onChange={handleChange}
          value={form.pomodoro_duration}
        />
      </Form.Field>
      <div>
        <Button onClick={onSubmit}>Next</Button>
      </div>
    </Form>
  );
};

export default AddProject;
