import React from 'react';
import Project from './Project';
import ProjectList from './ProjectsList';

const Projects = ({ projects, viewAsList }) => {
  if (projects.length < 0) {
    return "You don't have any project yet";
  } else if (viewAsList) {
    return <ProjectList projects={projects} />;
  } else {
    return projects.map(project => (
      <Project key={project.id} {...project}></Project>
    ));
  }
};

export default Projects;
