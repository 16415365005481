import React, { useState } from 'react';
import { Input, Form, Grid, Button } from 'semantic-ui-react';
import { useAlert } from '../../hooks/useAlert';

const ResetPassword = ({ onSaveNewPassword }) => {
  const [password, setPassword] = useState('');
  const { showWarning } = useAlert();

  const validateForm = () => {
    let error = '';
    if (password.length < 8) {
      error = 'The password has to be 8 characters long';
      showWarning(error);
      return false;
    }
    return true;
  };

  const saveNewPassword = () => {
    if (validateForm()) {
      onSaveNewPassword(password);
    }
  };

  return (
    <Grid.Row>
      <Grid.Column>
        <Form onSubmit={saveNewPassword}>
          <h2>Reset your password</h2>
          <p>Type your password</p>
          <Form.Field>
            <label>New password</label>
            <Input
              name="password"
              type="password"
              onChange={e => setPassword(e.target.value)}
            ></Input>
          </Form.Field>
          <Button type="submit">Set new password</Button>
        </Form>
      </Grid.Column>
    </Grid.Row>
  );
};

export default ResetPassword;
