import React from 'react';
import CancelModal from '../../../components/ConfirmModal';

const ConfirmCancel = ({ show, onConfirm, onCancel }) => {
  return (
    <CancelModal
      modalVisible={show}
      header="Cancel current session"
      headerIcon="archive"
      content="you will lose your progress, are you sure?"
      onCancel={onCancel}
      onConfirm={onConfirm}
      cancelMessage="No, keep working"
      confirmMessage="Yes, cancel this session"
    />
  );
};

export default ConfirmCancel;
