export const getPomodoro = state => ({ ...state.pomodoro });

export const getTimer = state => ({ ...state.pomodoro.timer });

export const getPomodoroStatus = state => state.pomodoro.status;

export const getPomodoroObject = state => ({ ...state.pomodoro.pomodoro });

export const getHistory = state => ({
  ...state.pomodoro.history,
  pomodoros: state.pomodoro.history.pomodoros.map(pomodoro => ({ ...pomodoro }))
});

export const getProjects = state =>
  state.pomodoro.projects.map(project => ({ ...project }));

export const getPomodoroProject = state => state.pomodoro.pomodoro.project;

export const getPomodoroDuration = state => state.pomodoro.pomodoro.duration;

export const getIsAlertOpen = state => state.pomodoro.alertOpen;

export const getShowConfirmCancel = state =>
  state.pomodoro.timer.showConfirmCancel;
