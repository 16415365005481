import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Form, Input } from 'semantic-ui-react';

const SearchProject = ({ projects, onApplyFilter }) => {
  const [filter, setFilter] = useState('');

  const filterProjects = (projects, value) => {
    const valueLowercase = value.toLowerCase();
    return projects.filter(p => p.name.toLowerCase().includes(valueLowercase));
  };

  const onChange = ({ target }) => {
    setFilter(target.value);
    onApplyFilter && onApplyFilter(filterProjects(projects, target.value));
  };
  return (
    <div>
      <Form>
        <Form.Field>
          <label>Search project</label>
          <Input onChange={onChange} value={filter}></Input>
        </Form.Field>
      </Form>
    </div>
  );
};

SearchProject.propTypes = {
  projects: PropTypes.array,
  onApplyFilter: PropTypes.func.isRequired
};

export default SearchProject;
