import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Redirect } from 'react-router-dom';
import Login from '../Login';
import { getIsAuthenticated } from '../authenticationSelectors';
import { login, authenticatedSuccess } from '../actions';
import { Auth } from '../../../../agent';
import * as style from './style.module.css';

const LoginContainer = () => {
  const isAuthenticated = useSelector(getIsAuthenticated);
  const dispatch = useDispatch();
  if (!isAuthenticated && Auth.hasCookies()) {
    Auth.configHeaders();
    dispatch(authenticatedSuccess());
  }
  return (
    <>
      {isAuthenticated ? (
        <Redirect to="/"></Redirect>
      ) : (
        <div className={style.loginContainer}>
          <Login onSuccess={credentials => dispatch(login(credentials))} />
        </div>
      )}
    </>
  );
};

export default LoginContainer;
