import React, { useState } from 'react';
import { Input, Form, Grid, Button } from 'semantic-ui-react';

const RecoverPassword = ({ onSuccess }) => {
  const [email, setEmail] = useState('');

  const login = () => onSuccess(email);

  return (
    <Grid.Row>
      <Grid.Column>
        <Form onSubmit={login}>
          <h2>Forgot your password?</h2>
          <p>Type your password</p>
          <Form.Field>
            <label>Email</label>
            <Input
              name="username"
              onChange={e => setEmail(e.target.value)}
            ></Input>
          </Form.Field>
          <Button type="submit">Send link</Button>
        </Form>
      </Grid.Column>
    </Grid.Row>
  );
};

export default RecoverPassword;
