import React from 'react';
import { BrowserRouter, Route, Switch, Redirect } from 'react-router-dom';
import MainScreenContainer from './common/features/MainScreen/MainScreenContainer';
import ProjectsPage from './common/features/Projects/ProjectsPage';
import { PrivateRoute } from './common/components/PrivateRoute';
import LoginContainer from './common/features/Authentication/LoginContainer';
import ProjectPage from './common/features/Projects/ProjectPage';
import CreateProjectPage from './common/features/Projects/CreateProjectPage';
import ErrorHandling from './common/features/ErrorHandling/ErrorHandling';
import AcknoledgeModalContainer from './common/features/MainScreen/AcknoledgeModalContainer';
import Loader from './common/components/Loader';
import SettingsPage from './common/features/Settings/SettingsPage';
import SignUpContainer from './common/features/Authentication/SignUpContainer';
import RecoverPasswordContainer from './common/features/Authentication/RecoverPasswordContainer';
import ResetPasswordContainer from './common/features/Authentication/ResetPasswordContainer';
import WizardPage from './common/features/Wizard/WizardPage';

const Router = () => {
  return (
    <BrowserRouter>
      <Loader></Loader>
      <ErrorHandling></ErrorHandling>
      <AcknoledgeModalContainer></AcknoledgeModalContainer>
      <Switch>
        <PrivateRoute path="/projects/create" component={CreateProjectPage} />
        <PrivateRoute path="/projects/:projectId" component={ProjectPage} />
        <PrivateRoute path="/settings" component={SettingsPage} />
        <PrivateRoute path="/projects" component={ProjectsPage} />
        <PrivateRoute path="/timer" component={MainScreenContainer} />
        <PrivateRoute path="/wizard" component={WizardPage} />
        <Route path="/login" component={LoginContainer} />
        <Route path="/signup" component={SignUpContainer} />
        <Route path="/recover-password" component={RecoverPasswordContainer} />
        <Route
          path="/reset-password/:recoverCode"
          component={ResetPasswordContainer}
        />
        <Redirect path="/" to="/timer" />
      </Switch>
    </BrowserRouter>
  );
};

export default Router;
