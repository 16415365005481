import React from 'react';
import PropTypes from 'prop-types';
import { Modal, Button, Header, Icon } from 'semantic-ui-react';

const CancelModal = ({
  modalVisible,
  header,
  headerIcon,
  content,
  confirmMessage,
  onConfirm,
  cancelMessage,
  onCancel
}) => {
  return (
    <Modal open={modalVisible} basic size="small">
      <Header icon={headerIcon} content={header} />
      <Modal.Content>
        <p>{content}</p>
      </Modal.Content>
      <Modal.Actions>
        <Button basic inverted onClick={onCancel}>
          <Icon name="remove" /> {cancelMessage || 'close'}
        </Button>
        <Button color="red" inverted onClick={onConfirm}>
          <Icon name="checkmark" /> {confirmMessage}
        </Button>
      </Modal.Actions>
    </Modal>
  );
};

CancelModal.propTypes = {
  modalVisible: PropTypes.bool.isRequired,
  header: PropTypes.string,
  headerIcon: PropTypes.string,
  content: PropTypes.string,
  confirmMessage: PropTypes.string,
  onConfirm: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired
};

export default CancelModal;
