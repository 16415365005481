import React from 'react';
import { Table, Pagination, Segment, Icon, Header } from 'semantic-ui-react';
import styles from './styles.module.css';
import { timeHelper } from '../../utils/timeHelper';

const Session = ({ project, date, duration, id }) => {
  return (
    <Table.Row key={id} className={styles.project}>
      <Table.Cell>{project ? project.name : 'Undefined'}</Table.Cell>
      <Table.Cell>{timeHelper.displayTimeForHuman(duration)}</Table.Cell>
      <Table.Cell>{timeHelper.displayDateTimeForHumans(date)}</Table.Cell>
    </Table.Row>
  );
};

const SessionsTable = ({
  sessions,
  showPagination,
  onPageChange,
  totalPages
}) => {
  if (sessions.length === 0) {
    return (
      <>
        <Segment className={styles.placeholder} placeholder>
          <Header icon>
            <Icon name="battery empty"></Icon>
            Empty
          </Header>
        </Segment>
      </>
    );
  }
  return (
    <>
      <Table className={styles.table} basic="very" selectable>
        <Table.Header>
          <Table.Row className={styles.header}>
            <Table.HeaderCell>Project</Table.HeaderCell>
            <Table.HeaderCell>Length</Table.HeaderCell>
            <Table.HeaderCell>Time ago</Table.HeaderCell>
          </Table.Row>
        </Table.Header>

        <Table.Body>{sessions.map(Session)}</Table.Body>
      </Table>
      {showPagination && (
        <Pagination
          onPageChange={(event, data) => {
            onPageChange(data.activePage);
          }}
          defaultActivePage={1}
          totalPages={totalPages}
        />
      )}
    </>
  );
};

export default SessionsTable;
