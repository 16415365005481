import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import SessionsTable from '../../../../components/SessionsTable';
import {
  getSessionsByProject,
  getSessionsByProjectCurrentPage,
  getSessionsByProjectNumPages,
  isFetchingSessionsByProject
} from '../../selectors';
import {
  fetchSessionsByProject,
  updateCurrentPageSessionsByProject,
  cleanSessionsByProject
} from '../../actions';
import { Dimmer, Loader } from 'semantic-ui-react';

const SessionsTableContainer = ({ projectId }) => {
  const dispatch = useDispatch();

  const sessions = useSelector(getSessionsByProject);
  const currentPage = useSelector(getSessionsByProjectCurrentPage);
  const numPages = useSelector(getSessionsByProjectNumPages);
  const isLoading = useSelector(isFetchingSessionsByProject);

  useEffect(() => {
    dispatch(fetchSessionsByProject(projectId, currentPage));
  }, [dispatch, projectId, currentPage]);

  useEffect(() => () => dispatch(cleanSessionsByProject()), [dispatch]);

  if (isLoading && sessions.length === 0) {
    return (
      <Dimmer active inverted>
        <Loader></Loader>;
      </Dimmer>
    );
  }

  return (
    <SessionsTable
      totalPages={numPages}
      sessions={sessions}
      showPagination
      onPageChange={pageIndex =>
        dispatch(updateCurrentPageSessionsByProject(pageIndex))
      }
    />
  );
};

export default SessionsTableContainer;
