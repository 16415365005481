import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import AcknoledgeModal from './AcknoledgeModal';
import { getIsAlertOpen, getPomodoroStatus } from './selectors';
import {
  startBreak,
  acknowledgeTimerFinished,
  cancelBreak,
  startPomodoro
} from './actions';

const AcknoledgeModalContainer = props => {
  const dispatch = useDispatch();
  const alertOpen = useSelector(getIsAlertOpen);
  const timerStatus = useSelector(getPomodoroStatus);
  const [modalContent, setModalContent] = useState({
    header: 'Pomodoro finished',
    content: 'Pomodoro finished',
    buttonRight: {
      text: 'Skip break',
      action: () => {
        dispatch(cancelBreak());
        dispatch(acknowledgeTimerFinished());
      }
    },
    buttonLeft: {
      text: 'Start break',
      action: () => {
        dispatch(startBreak());
        dispatch(acknowledgeTimerFinished());
      }
    }
  });

  useEffect(() => {
    if (timerStatus === 'awaiting_pomodoro') {
      setModalContent({
        header: 'Break finished',
        content: 'Break finished',
        buttonRight: {
          text: 'Close',
          action: () => {
            dispatch(acknowledgeTimerFinished());
          }
        },
        buttonLeft: {
          text: 'Start pomodoro',
          action: () => {
            dispatch(startPomodoro());
            dispatch(acknowledgeTimerFinished());
          }
        }
      });
    } else if (timerStatus === 'awaiting_break') {
      setModalContent({
        header: 'Pomodoro finished',
        content: 'Pomodoro finished',
        buttonRight: {
          text: 'Skip break',
          action: () => {
            dispatch(cancelBreak());
            dispatch(acknowledgeTimerFinished());
          }
        },
        buttonLeft: {
          text: 'Start break',
          action: () => {
            dispatch(startBreak());
            dispatch(acknowledgeTimerFinished());
          }
        }
      });
    }
  }, [alertOpen, dispatch, timerStatus]);

  return (
    <AcknoledgeModal
      closeModal={() => {
        dispatch(acknowledgeTimerFinished());
      }}
      visible={alertOpen}
      {...modalContent}
    ></AcknoledgeModal>
  );
};

export default AcknoledgeModalContainer;
