import React, { useState } from 'react';
import { Button, Dimmer, Modal, Header, Icon } from 'semantic-ui-react';
import { initInstance } from '../../../firebase';
import { useAlert } from '../../../hooks/useAlert';
import * as styles from './styles.module.css';

const ActivateNotifications = props => {
  const notificationActive = Notification.permission === 'granted';
  const { showWarning } = useAlert();
  const [askingPermission, setAskingPermission] = useState(false);
  const [permissionGranted, setPermissionGranted] = useState(
    notificationActive
  );
  const [modalVisible, setModalVisible] = useState(false);
  const onClick = () => {
    setAskingPermission(true);
    Notification.requestPermission(function (status) {
      setAskingPermission(false);
      if (status === 'granted') {
        setPermissionGranted(true);
        const firebase = initInstance();
        firebase.getToken();
      }
      if (status === 'denied') {
        showWarning(
          'It looks like you blocked notifications\n Set allow notifications in your browser'
        );
      }
    });
  };

  const onNext = () => {
    if (permissionGranted) {
      props.onNext();
    } else {
      setModalVisible(true);
    }
  };

  return (
    <>
      <Modal size="small" basic open={modalVisible}>
        <Header icon>
          <Icon name="bell slash outline" />
        </Header>
        <Modal.Content>
          <p>
            Are you sure? You won't be able to know when you have to take a
            break
          </p>
        </Modal.Content>
        <Modal.Actions>
          <Button onClick={() => setModalVisible(false)}>Go back</Button>
          <Button color="red" onClick={() => props.onNext()}>
            I don't want to receive notifications
          </Button>
        </Modal.Actions>
      </Modal>

      <div>
        <Dimmer active={askingPermission}></Dimmer>
        <h2>Activate notifications</h2>
        <div>
          <p>
            Activate notifications in order to let you know when your pomodoro
            has finished
          </p>
        </div>
        <div className={styles.buttonsWrapper}>
          <Button
            className={`${styles.activateButton} ${styles.button}`}
            disabled={permissionGranted}
            onClick={onClick}
          >
            {permissionGranted
              ? 'Notifications already activated'
              : 'Activate notifications'}
          </Button>
          <Button onClick={onNext}>
            {permissionGranted ? 'Next' : 'Skip'}
          </Button>
        </div>
      </div>
    </>
  );
};

export default ActivateNotifications;
