import React from 'react';
import MainContainer from '../../components/MainContainer';
import { Grid } from 'semantic-ui-react';
import SettingsFormContainer from './SettingsFormContainer';

const SettingsPage = () => {
  return (
    <MainContainer title="Settings">
      <Grid.Row>
        <Grid.Column>
          <SettingsFormContainer></SettingsFormContainer>
        </Grid.Column>
      </Grid.Row>
    </MainContainer>
  );
};

export default SettingsPage;
