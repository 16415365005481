import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import PropTypes, { number } from 'prop-types';
import { Modal, Button } from 'semantic-ui-react';
import { useToasts } from 'react-toast-notifications';
import {
  updateProject,
  checkProjectName,
  clearCheckProjectName
} from '../../actions';
import ProjectForm from '../../ProjectForm';
import styles from './styles.module.css';
import { getProjectNameForm } from '../../selectors';

const EditProjectModalContainer = ({
  isOpen,
  open,
  close,
  projectId,
  project
}) => {
  const [projectForm, setProjectForm] = useState({});

  const dispatch = useDispatch();
  const { addToast } = useToasts();
  let projectNameTimer = null;

  // Selectors
  const projectNameForm = useSelector(getProjectNameForm);

  // Functions
  const onUpdateProject = project => {
    dispatch(updateProject(project, projectId, postUpdate));
  };

  const postUpdate = () => {
    addToast('Project created', {
      appearance: 'success',
      autoDismiss: true
    });
    close();
  };

  /**
   * Wait until the user finish typping to
   * find if the project name exists
   * @param {string} projectName project name
   */
  const onProjectNameChange = projectName => {
    clearTimeout(projectNameTimer);
    projectNameTimer = setTimeout(() => {
      dispatch(checkProjectName(projectName, projectForm.name));
    }, 400);
  };

  /**
   * Check if project has a parent
   * if has a parent check if it's only its id
   * or the whole object
   */
  useEffect(() => {
    if (Object.keys(project).length > 0) {
      const { parent } = project;
      const projectCopy = { ...project };
      if (parent) {
        projectCopy.parent = typeof parent == number ? parent : parent.id;
      }
      setProjectForm(projectCopy);
    }
    return () => dispatch(clearCheckProjectName());
  }, [project, dispatch]);

  const closeModal = () => {
    close();
    dispatch(clearCheckProjectName());
  };

  return (
    <>
      <Button className={styles.editProject} onClick={open}>
        Edit Project
      </Button>
      <Modal size="small" open={isOpen} onClose={closeModal}>
        <ProjectForm
          project={projectForm}
          saveButtonLabel="Save project"
          saveProject={project => {
            onUpdateProject(project);
            dispatch(clearCheckProjectName());
          }}
          onCancel={closeModal}
          projectName={projectNameForm}
          onProjectNameChange={e => onProjectNameChange(e)}
        />
      </Modal>
    </>
  );
};

EditProjectModalContainer.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  open: PropTypes.func.isRequired,
  close: PropTypes.func.isRequired,
  projectId: PropTypes.string
};

export default EditProjectModalContainer;
