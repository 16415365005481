import React from 'react';
import { Modal, Button } from 'semantic-ui-react';

const AcknoledgeModal = ({
  visible,
  header,
  content,
  buttonLeft,
  buttonRight,
  closeModal
}) => {
  return (
    <Modal open={visible} closeIcon onClose={() => closeModal()}>
      <Modal.Header>{header}</Modal.Header>
      <Modal.Content>{content}</Modal.Content>
      <Modal.Actions>
        <Button color="green" onClick={() => buttonLeft.action()}>
          {buttonLeft.text}
        </Button>
        <Button color="teal" onClick={() => buttonRight.action()}>
          {buttonRight.text}
        </Button>
      </Modal.Actions>
    </Modal>
  );
};

export default AcknoledgeModal;
