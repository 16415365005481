import React from 'react';
import PropTypes from 'prop-types';
import { Icon } from 'semantic-ui-react';
import styles from './style.module.css';

const NavBarItem = ({ path, icon, location, title, pushLocation }) => {
  return (
    <div
      onClick={() => pushLocation(path)}
      className={`${
        location.includes(path) ? styles.selectedItem : styles.item
      }`}
    >
      <Icon className={styles.icon} name={icon} size="large" />
      {title}
    </div>
  );
};

NavBarItem.propTypes = {
  path: PropTypes.string.isRequired,
  icon: PropTypes.string.isRequired,
  location: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  pushLocation: PropTypes.func.isRequired
};

export default NavBarItem;
