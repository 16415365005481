import axios from 'axios';
import Cookies from 'universal-cookie';

const cookies = new Cookies();

const API_ROOT = process.env.REACT_APP_API_SERVER;

export const mediaServer = process.env.REACT_APP_MEDIA_SERVER;

axios.defaults.xsrfCookieName = 'csrftoken';
axios.defaults.xsrfHeaderName = 'X-CSRFToken';

const requests = {
  get: (url, params) => axios.get(`${API_ROOT}${url}`, { params }),
  post: (url, body) => axios.post(`${API_ROOT}${url}`, body),
  put: (url, body) => axios.put(`${API_ROOT}${url}`, body),
  delete: url => axios.delete(`${API_ROOT}${url}`)
};

export const CDN = {
  getUrl: imageUrl =>
    imageUrl.includes('http') || imageUrl.includes('base64')
      ? imageUrl
      : `${mediaServer}/${imageUrl}`
};

export const Auth = {
  login: credentials => requests.post('account/login/', credentials),
  signUp: userData => requests.post('account/signup/', userData),
  configHeaders: () => {
    const session_cookie = cookies.get('token');
    if (session_cookie !== undefined) {
      const token = `Token ${session_cookie}`;
      axios.defaults.headers.common['Authorization'] = token;
    }
    axios.defaults.xsrfCookieName = 'csrftoken';
    axios.defaults.xsrfHeaderName = 'X-CSRFToken';
  },
  configCookies: data => {
    const nextYear = new Date();
    nextYear.setFullYear(nextYear.getFullYear() + 1);
    cookies.set('csrftoken', data['csrftoken'], {
      path: '/',
      expires: nextYear
    });
    cookies.set('token', data['token'], { path: '/', expires: nextYear });
  },
  deleteAuthHeaders: () => {
    delete axios.defaults.headers.common.Authorization;
  },
  deleteCookies: () => {
    cookies.remove('csrftoken', { path: '/' });
    cookies.remove('token', { path: '/' });
    cookies.remove('sessionid', { path: '/' });
  },
  hasCookies: () => {
    const token = cookies.get('token');
    const csrfToken = cookies.get('csrftoken');
    if (token != null && csrfToken != null) {
      return true;
    }
    return false;
  },
  saveUserInfoLocalStorage: account =>
    localStorage.setItem('user', JSON.stringify(account)),
  saveSettingsLocalStorage: settings =>
    localStorage.setItem('settings', JSON.stringify(settings)),
  getUserInfoLocalStorage: () => {
    const user = localStorage.getItem('user');
    return JSON.parse(user);
  },
  getSettingsLocalStorage: () => {
    const settings = localStorage.getItem('settings');
    return JSON.parse(settings);
  },
  recoverPassword: email =>
    requests.post('account/recover_password/', { email }),
  resetPassword: (recoverCode, newPassword) =>
    requests.post('account/reset_password/', { recoverCode, newPassword }),
  validateRecoverCode: recoverCode =>
    requests.post('account/validate_recover_code/', { recoverCode })
};

export const Projects = {
  all: {
    detailed: () => requests.get('pomodoro/projects/', { detailed: true }),
    parents: () => requests.get('pomodoro/projects/', { parents: true }),
    default: () => requests.get('pomodoro/projects/')
  },
  get: projectId => requests.get(`pomodoro/projects/${projectId}/`),
  create: project => requests.post(`pomodoro/projects/`, project),
  update: (project, projectId) =>
    requests.put(`pomodoro/projects/${projectId}/`, project),
  delete: projectId => requests.delete(`pomodoro/projects/${projectId}/`),
  getSessions: (projectId, page) =>
    requests.get(`pomodoro/projects/${projectId}/sessions/`, { page }),
  getSessionsHeatmap: projectId =>
    requests.get(`pomodoro/projects/${projectId}/sessions_heatmap/`),
  checkName: (projectName, currentName) =>
    requests.get(`pomodoro/projects/check_name/`, { projectName, currentName })
};

export const Pomodoros = {
  create: pomodoro => requests.post('pomodoro/pomodoros/', pomodoro),
  save: (pomodoroId, pomodoro) =>
    requests.post(`pomodoro/pomodoros/${pomodoroId}/save_session/`, pomodoro),
  history: (start, end) => requests.get('pomodoro/pomodoros/', { start, end }),
  lastPomodoro: () => requests.get('pomodoro/pomodoros/last_session/'),
  cancel: pomodoroId =>
    requests.post(`pomodoro/pomodoros/${pomodoroId}/cancel/`),
  firstTime: project =>
    requests.post('pomodoro/pomodoros/first_time/', { project })
};

export const Breaks = {
  create: breakObject => requests.post('pomodoro/breaks/', breakObject)
};

export const Settings = {
  update: (settings, settingsId) =>
    requests.put(`account/settings/${settingsId}/`, settings),
  get: () => requests.get('account/settings/')
};

export const Devices = {
  create: device => requests.post('account/devices/', device)
};
