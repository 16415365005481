import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import PropTypes from 'prop-types';
import DeleteProject from './DeleteProject';
import { useToasts } from 'react-toast-notifications';
import { Button } from 'semantic-ui-react';
import { deleteProject } from '../../actions';
import { timeHelper } from '../../../../utils/timeHelper';

const DeleteProjectContainer = ({ project }) => {
  const [modalVisible, setModalVisible] = useState(false);

  const { addToast } = useToasts();
  const history = useHistory();
  const dispatch = useDispatch();

  // Functions
  const close = () => setModalVisible(false);

  const onSuccess = () => {
    history.push('/projects');
    addToast('Project deleted successfully', {
      appearance: 'success',
      autoDismiss: true
    });
  };

  const onFailure = () => setModalVisible(false);

  const onDelete = () => {
    dispatch(deleteProject(project.id, onSuccess, onFailure));
  };

  return (
    <>
      <Button onClick={() => setModalVisible(true)}>Delete project</Button>
      <DeleteProject
        project={project}
        close={close}
        onDelete={onDelete}
        modalVisible={modalVisible}
        warningLabel={`You worked in ${
          project.name
        } for ${timeHelper.displayTimeForHuman(project.duration)}. Are you
        sure you want to delete it?`}
      />
    </>
  );
};

DeleteProjectContainer.propTypes = {
  project: PropTypes.object
};

export default DeleteProjectContainer;
