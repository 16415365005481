import React from 'react';
import ConfirmCancel from '../ConfirmCancel';
import { useDispatch, useSelector } from 'react-redux';
import * as actions from '../actions';
import * as selectors from '../selectors';
const ConfirmCancelContainer = () => {
  const dispatch = useDispatch();
  const show = useSelector(selectors.getShowConfirmCancel);

  const closeModal = () => dispatch(actions.showConfirmCancelSession(false));
  const onCancel = () => closeModal();
  const onConfirm = () => {
    dispatch(actions.cancelPomodoro());
    closeModal();
  };

  return (
    <ConfirmCancel show={show} onConfirm={onConfirm} onCancel={onCancel} />
  );
};

export default ConfirmCancelContainer;
