import React, { useEffect } from 'react';
import { Link } from 'react-router-dom';
import { Form } from 'semantic-ui-react';
import DropdownInput from '../../components/DropdownInput';
import { timeHelper } from '../../utils/timeHelper';
import { useForm } from '../../hooks/formHooks';

const PomodoroForm = ({
  onProjectChange,
  onDurationChange,
  projects,
  pomodoro
}) => {
  const initForm = pomodoro => ({
    project: pomodoro.project || '',
    duration: pomodoro.duration || ''
  });
  const [form, , setForm] = useForm(initForm(pomodoro || {}));
  useEffect(() => {
    setForm(initForm(pomodoro || {}));
  }, [pomodoro, setForm]);

  const projectsPlaceholder = (
    <div>
      <p>
        It looks like you don't have any project yet. To create a new project
        click <Link to="/projects/create">Here</Link>
      </p>
    </div>
  );

  return (
    <Form>
      <Form.Field>
        <label>Project</label>
        <DropdownInput
          className="form-ph-dropdown"
          name="project"
          onChange={(e, { value }) => {
            onProjectChange(projects.find(project => project.id === value));
          }}
          placeholder="Select Project"
          options={projects}
          value={form.project}
        />
        {projects.length === 0 && projectsPlaceholder}
      </Form.Field>
      <Form.Field>
        <label>Duration</label>
        <DropdownInput
          className="form-ph-dropdown"
          name="duration"
          onChange={(e, { value }) => onDurationChange(value)}
          placeholder="Select project duration"
          options={timeHelper.pomodorosDurationOptions}
          value={form.duration}
        />
      </Form.Field>
    </Form>
  );
};

export default PomodoroForm;
