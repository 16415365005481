import React from 'react';
import { Grid, Container } from 'semantic-ui-react';
import { timeHelper } from '../../../utils/timeHelper';
import styles from './style.module.css';
import Statistic from '../../../components/Statistic';
import SessionsTable from '../../../components/SessionsTable';

const PomodoroHistory = ({ pomodoros, totalTime, breakDuration }) => {
  return (
    <>
      <Container>
        <Grid.Row>
          <h1> Today </h1>
          <Grid className={styles.statisticsWrapper} columns="equal" divided>
            <Statistic
              title={timeHelper.displayTimeForHuman(totalTime)}
              label="Total Time"
              icon="calculator"
            />
            <Statistic
              title={pomodoros.length.toString()}
              label="Sessions completed"
              icon="hourglass end"
            />
            <Statistic
              title={timeHelper.displayTimeForHuman(breakDuration)}
              label="Break duration"
              icon="coffee"
            />
          </Grid>
        </Grid.Row>
        <br />
        <h1>Sessions completed</h1>
        <Grid.Row className={styles.historyWrapper}>
          <SessionsTable sessions={pomodoros} />
        </Grid.Row>
      </Container>
    </>
  );
};

export default PomodoroHistory;
