import React from 'react';
import { Step } from 'semantic-ui-react';
import * as styles from './styles.module.css';

const WizardStep = ({ title, completed = false, active = false }) => {
  return (
    <Step completed={completed} active={active}>
      <Step.Content>
        <Step.Title className={styles.step}>{title}</Step.Title>
      </Step.Content>
    </Step>
  );
};

export default WizardStep;
