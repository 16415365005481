import { Pomodoros, Breaks } from '../../../agent';
import pomodoroSound from '../../media/sound/to-the-point.mp3';
import breakSound from '../../media/sound/gentle-alarm.mp3';
import startSound from '../../media/sound/start-session.mp3'
import { getPreferenceBreakDuration } from '../Authentication/authenticationSelectors';
import { getPomodoroObject } from './selectors';

export const START_POMODORO = 'MAIN_SCREEN/START_POMODORO';
export const START_POMODORO_SUCCESS = 'START_POMODORO_SUCCESS';
export const START_POMODORO_FAILURE = 'START_POMODORO_FAILURE';

export const startPomodoroReducer = pomodoro => ({
  type: START_POMODORO,
  payload: { startDate: pomodoro.date, id: pomodoro.id }
});

export const startPomodoroSuccess = pomodoro => ({
  type: START_POMODORO_SUCCESS,
  pomodoro
});

export const startPomodoro = () => {
  return (dispatch, getState) => {
    document.title = 'Working | Productivity Hub';
    const pomodoro = getPomodoroObject(getState());
    pomodoro.date = new Date();
    const audio = new Audio(startSound)
    audio.volume = 0.4;
    audio.play();
    return Pomodoros.create(pomodoro)
      .then(r => {
        const pomodoroCreated = r.data;
        dispatch({
          type: START_POMODORO,
          payload: { startDate: pomodoro.date, id: pomodoroCreated.id }
        });
        dispatch(startPomodoroSuccess());
      })
      .catch(e => {
        if (e.response && e.response.data) {
          const { error, last_session } = e.response.data;
          if (error === 'another session is running') {
            dispatch(loadPomodoro(last_session));
            return;
          }
        }
        dispatch({ type: START_POMODORO_FAILURE, payload: e });
      });
  };
};

export const LOAD_POMODORO = 'LOAD_POMODORO';
export const loadPomodoro = pomodoro => ({
  type: LOAD_POMODORO,
  payload: pomodoro
});

export const PREPARE_TIMER_FOR_BREAK = 'PREPARE_TIMER_FOR_BREAK';

export const prepareTimerForBreak = () => {
  return (dispatch, getState) => {
    const breakDuration = getPreferenceBreakDuration(getState());
    dispatch({
      type: PREPARE_TIMER_FOR_BREAK,
      payload: { breakDuration }
    });
  };
};

export const PREPARE_TIMER_FOR_POMODORO = 'PREPARE_TIMER_FOR_POMODORO';

export const prepareTimerForPomodoro = payload => ({
  type: PREPARE_TIMER_FOR_POMODORO
});

export const START_BREAK = 'MAIN_SCREEN/START_BREAK';

export const startBreak = payload => {
  document.title = 'In break | Productivity Hub';
  return {
    type: START_BREAK,
    payload
  };
};

export const SHOW_CONFIRM_CANCEL_SESSION = 'SHOW_CONFIRM_CANCEL_SESSION';
export const showConfirmCancelSession = payload => ({
  type: SHOW_CONFIRM_CANCEL_SESSION,
  payload
});

export const CANCEL_POMODORO = 'MAIN_SCREEN/CANCEL_POMODORO';
export const CANCEL_POMODORO_FAILURE = 'MAIN_SCREEN/CANCEL_POMODORO_FAILURE ';

export const cancelPomodoro = () => {
  return (dispatch, getState) => {
    const pomodoro = getPomodoroObject(getState());
    return Pomodoros.cancel(pomodoro.id)
      .catch(e => {
        // The session was already deleted
        if (e.status !== 404) {
          dispatch({ type: CANCEL_POMODORO_FAILURE, payload: e });
        }
      })
      .finally(r => dispatch({ type: CANCEL_POMODORO }));
  };
};

export const CANCEL_BREAK = 'MAIN_SCREEN/CANCEL_BREAK';

export const cancelBreak = payload => ({
  type: CANCEL_BREAK,
  payload
});

export const SAVE_POMODORO_START = 'SAVE_POMODORO_START';
export const SAVE_POMODORO_SUCCESS = 'SAVE_POMODORO_SUCCESS';
export const SAVE_POMODORO_FAILURE = 'SAVE_POMODORO_FAILURE';

export const savePomodoroSuccess = payload => ({
  type: SAVE_POMODORO_SUCCESS,
  payload
});

export const savePomodoroFailure = payload => ({
  type: SAVE_POMODORO_FAILURE,
  payload
});

export const savePomodoroStart = () => ({
  type: SAVE_POMODORO_START
});

export const savePomodoro = payload => {
  return dispatch => {
    document.title = 'Pomodoro finsihed | Productivity Hub';
    const audio = new Audio(pomodoroSound);
    audio.volume = 0.4
    audio.play();
    dispatch(savePomodoroStart());
    dispatch(prepareTimerForBreak());
    const pomodoro = { ...payload, date: payload.start };
    delete pomodoro.start;
    delete pomodoro.count;
    return Pomodoros.save(pomodoro.id, pomodoro)
      .then(r => {
        dispatch(savePomodoroSuccess(r.data));
        dispatch(fetchHistory());
      })
      .catch(e => dispatch(savePomodoroFailure(e)));
  };
};

export const SAVE_BREAK = 'SAVE_BREAK';
export const SAVE_BREAK_SUCCESS = 'SAVE_BREAK_SUCCESS';
export const SAVE_BREAK_FAILURE = 'SAVE_BREAK_FAILURE';

export const saveBreakSuccess = payload => ({
  type: SAVE_BREAK_SUCCESS,
  payload
});

export const saveBreakFailure = payload => ({
  type: SAVE_BREAK_FAILURE,
  payload
});

export const saveBreak = payload => {
  return dispatch => {
    const breakObject = { ...payload, date: payload.start };
    delete breakObject.start;
    const audio = new Audio(breakSound);
    dispatch({
      type: SAVE_BREAK,
      payload: audio
    });
    dispatch(prepareTimerForPomodoro());
    document.title = 'Break finished | Productivity Hub';
    if (Notification.permission === 'granted') {
      navigator.serviceWorker.getRegistration().then(function (reg) {
        reg && reg.showNotification('Break finished');
      });
    }
    Breaks.create(breakObject)
      .then(r => dispatch(saveBreakSuccess(r)))
      .catch(e => dispatch(saveBreakFailure(e)));
  };
};

export const CHANGE_POMODORO_DURATION = 'CHANGE_POMODORO_DURATION';

export const changePomodoroDuration = payload => ({
  type: CHANGE_POMODORO_DURATION,
  payload
});

export const SELECT_POMODORO_PROJECT = 'SELECT_POMODORO_PROJECT';

export const selectPomodoroProject = payload => ({
  type: SELECT_POMODORO_PROJECT,
  payload
});

export const ACKNOWLEDGE_TIMER_FINISHED = 'ACKNOWLEDGE_TIMER_FINISHED';

export const acknowledgeTimerFinished = () => ({
  type: ACKNOWLEDGE_TIMER_FINISHED
});

export const FETCH_HISTORY_START = 'FETCH_HISTORY_START';
export const FETCH_HISTORY_SUCCESS = 'FETCH_HISTORY_SUCCESS';
export const FETCH_HISTORY_FAILURE = 'FETCH_HISTORY_FAILURE';

export const fetchHistoryStart = () => ({
  type: FETCH_HISTORY_START
});

export const fetchHistorySuccess = payload => ({
  type: FETCH_HISTORY_SUCCESS,
  payload
});

export const fetchHistoryFailure = payload => ({
  type: FETCH_HISTORY_FAILURE,
  payload
});

export const fetchHistory = () => {
  return dispatch => {
    dispatch(fetchHistoryStart());
    const today = new Date();
    const start = new Date(
      today.getFullYear(),
      today.getMonth(),
      today.getDate(),
      0,
      0
    );
    return Pomodoros.history(start, null)
      .then(r => dispatch(fetchHistorySuccess(r.data)))
      .catch(e => dispatch(fetchHistoryFailure(e)));
  };
};

export const GET_LAST_POMODORO_SUCCESS = 'GET_LAST_POMODORO_SUCCESS';

export const getLastPomodoroSuccess = pomodoro => {
  return {
    type: GET_LAST_POMODORO_SUCCESS,
    payload: pomodoro
  };
};

export const GET_LAST_POMODORO_FAILURE = 'GET_LAST_POMODORO_FAILURE';

export const getLastPomodoroFailure = () => {
  return {
    type: GET_LAST_POMODORO_FAILURE
  };
};

export const GET_LAST_POMODORO = 'GET_LAST_POMODORO';

export const getLastPomodoro = () => {
  return dispatch => {
    dispatch({
      type: GET_LAST_POMODORO
    });
    return Pomodoros.lastPomodoro()
      .then(r => dispatch(getLastPomodoroSuccess(r.data)))
      .catch(e => dispatch(getLastPomodoroFailure(e)));
  };
};
