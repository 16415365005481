import React from 'react';
import { Grid } from 'semantic-ui-react';
import MainContainer from '../../../components/MainContainer';
import CreateProjectContainer from '../CreateProjectContainer';

const CreateProjectPage = () => {
  return (
    <MainContainer title="Project">
      <Grid.Row>
        <Grid.Column>
          <CreateProjectContainer />
        </Grid.Column>
      </Grid.Row>
    </MainContainer>
  );
};

export default CreateProjectPage;
