import React from 'react';
import { useSelector } from 'react-redux';

import Stats from './Stats';
import { getProject } from '../../selectors';
import { timeHelper } from '../../../../utils/timeHelper';
import { Loader, Dimmer } from 'semantic-ui-react';

const StatsContainer = () => {
  const project = useSelector(getProject);

  if (Object.keys(project).length === 0) {
    return (
      <Dimmer active inverted>
        <Loader></Loader>;
      </Dimmer>
    );
  }

  return (
    <Stats
      parent={project.parent}
      sessionsCompleted={project.sessions_completed}
      sessionDuration={timeHelper.displayTimeForHuman(
        project.pomodoro_duration
      )}
      totalTime={timeHelper.displayTimeForHuman(project.duration)}
    />
  );
};

export default StatsContainer;
