export const getProjects = state =>
  state.project.projects.map(project => {
    const newProject = { ...project };
    return newProject;
  });

export const getProject = state => state.project.project;

export const getSessionsByProject = state => state.project.sessions.results;

export const getSessionsByProjectCurrentPage = state =>
  state.project.sessions.currentPage;

export const isFetchingSessionsByProject = state =>
  state.project.sessions.isFetching;

export const getSessionsByProjectNumPages = state =>
  state.project.sessions.numPages;

export const getSessionsHeatmap = state => state.project.heatmap.sessions;

export const getSessionsMaxHeatmap = state => state.project.heatmap.max;

export const getProjectNameForm = state => state.project.form.name;
