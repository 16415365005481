import React from 'react';
import PropTypes from 'prop-types';
import { Container, Grid } from 'semantic-ui-react';
import Statistic from '../../../../../components/Statistic';
import styles from './style.module.css';

const Stats = ({ parent, sessionsCompleted, sessionDuration, totalTime }) => {
  return (
    <Container className={styles.stats}>
      <Grid className={styles.statisticsWrapper} columns="equal">
        <Statistic
          label="Parent project"
          icon="folder outline"
          title={parent ? parent.name : '----'}
        ></Statistic>
        <Statistic
          label="Total time"
          icon="trash alternate outline"
          title={totalTime}
        ></Statistic>
      </Grid>
      <Grid className={styles.statisticsWrapper} columns="equal">
        <Statistic
          label="Session duration"
          icon="clock outline"
          title={sessionDuration}
        ></Statistic>
        <Statistic
          label="Sessions completed"
          icon="check circle outline"
          title={sessionsCompleted.toString()}
        ></Statistic>
      </Grid>
    </Container>
  );
};

Stats.propTypes = {
  parent: PropTypes.object,
  sessionsCompleted: PropTypes.number.isRequired,
  sessionDuration: PropTypes.string.isRequired,
  totalTime: PropTypes.string.isRequired
};

export default Stats;
