import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { getIsAuthenticated } from '../authenticationSelectors';
import { authenticatedSuccess, signUp } from '../actions';
import { Auth } from '../../../../agent';
import SignUp from '../SignUp';
import Presentation from '../Presentation';
import * as styles from './styles.module.css';

const SignUpContainer = () => {
  const isAuthenticated = useSelector(getIsAuthenticated);
  const history = useHistory();
  const dispatch = useDispatch();

  if (!isAuthenticated && Auth.hasCookies()) {
    Auth.configHeaders();
    dispatch(authenticatedSuccess());
  }

  if (isAuthenticated) {
    history.push('/');
  }

  const onSuccess = credentials => {
    dispatch(
      signUp(credentials, () => {
        history.replace('/wizard');
      })
    );
  };

  return (
    <div className={styles.signupContainer}>
      <Presentation />
      <SignUp onSuccess={onSuccess} />
    </div>
  );
};

export default SignUpContainer;
