import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useToasts } from 'react-toast-notifications';
import { getGlobalError } from './selectors';
import { forceLogout } from '../Authentication/actions';
import { getIsAuthenticated } from '../Authentication/authenticationSelectors';

const ErrorHandling = props => {
  const dispatch = useDispatch();
  const { addToast } = useToasts();
  const error = useSelector(getGlobalError);
  const isAuthenticated = useSelector(getIsAuthenticated);

  useEffect(() => {
    if (
      error.error &&
      error.error.response &&
      (error.error.response.status === 401 ||
        error.error.response.status === 403) &&
      isAuthenticated
    ) {
      dispatch(forceLogout());
    }
    if (error && error.warned === false) {
      let errorMessage = error.message;
      if (error.error.response && error.error.response.data) {
        errorMessage = error.error.response.data;
      }
      addToast(errorMessage, {
        appearance: 'error',
        autoDismiss: true
      });
      dispatch({ type: 'ERROR_WARNED' });
    }
  }, [error, dispatch, addToast, isAuthenticated]);
  return <></>;
};

export default ErrorHandling;
