import React from 'react';
import { useSelector } from 'react-redux';
import { Grid } from 'semantic-ui-react';
import MainContainer from '../../../components/MainContainer';
import PomodoroButtonContainer from '../PomodoroButtonContainer/PomodoroButtonContainer';
import TimerContainer from '../TimerContainer';
import { getPomodoro } from '../selectors';
import PomodoroHistoryContainer from '../PomodoroHistoryContainer';
import PomodoroFormContainer from '../PomodoroFormContainer';
import ConfirmCancelContainer from '../ConfirmCancelContainer';

const MainScreenContainer = () => {
  const pomodoroState = useSelector(getPomodoro);

  return (
    <MainContainer title="Work">
      <ConfirmCancelContainer />
      <Grid.Row>
        <Grid.Column mobile={16} tablet={8} computer={5}>
          <Grid.Column mobile={16} tablet={8} computer={8}>
            <TimerContainer>
              <PomodoroButtonContainer
                pomodoroState={pomodoroState.status}
              ></PomodoroButtonContainer>
            </TimerContainer>
          </Grid.Column>
          <Grid.Column mobile={16} tablet={8} computer={8}>
            <PomodoroFormContainer></PomodoroFormContainer>
          </Grid.Column>
        </Grid.Column>
        <Grid.Column mobile={16} tablet={8} computer={6}>
          <Grid.Column mobile={16} tablet={8} computer={5}>
            <PomodoroHistoryContainer></PomodoroHistoryContainer>
          </Grid.Column>
        </Grid.Column>
        <Grid.Column mobile={16} tablet={8} computer={5}></Grid.Column>
      </Grid.Row>
      <Grid.Row>{/* Here goes the leaderboard */}</Grid.Row>
    </MainContainer>
  );
};

export default MainScreenContainer;
