import React from 'react';
import styles from './style.module.css';
import { timeHelper } from '../../../utils/timeHelper';
import { Grid } from 'semantic-ui-react';
import { useGoTo } from '../../../hooks/useRouterHooks';

const Project = props => {
  const { id, parent, name, duration } = props;
  const [goTo] = useGoTo();

  return (
    <Grid.Column>
      <div className={styles.project} onClick={() => goTo(`/projects/${id}`)}>
        <Grid padded={true}>
          <Grid.Row className={styles.title} columns={parent ? 2 : 1}>
            <Grid.Column>
              <div className={styles.name}> {name} </div>
            </Grid.Column>
          </Grid.Row>
          <div className={styles.duration}>
            {timeHelper.displayTimeForHuman(duration)}
          </div>
          <div className={styles.time}>Time spent</div>
        </Grid>
      </div>
    </Grid.Column>
  );
};

export default Project;
