import React, { useState, useEffect } from 'react';
import { Step } from 'semantic-ui-react';
import WizardStep from '../WizardStep';
import MainContainer from '../../../components/MainContainer';
import AddProject from '../AddProject';
import ActivateNotifications from '../ActivateNotifications';
import StartWork from '../StartWork';
import { useHistory } from 'react-router-dom';
import { Pomodoros } from '../../../../agent';
import { useDispatch } from 'react-redux';
import {
  startPomodoroSuccess,
  startPomodoroReducer,
  changePomodoroDuration,
  selectPomodoroProject
} from '../../MainScreen/actions';
import * as styles from './styles.module.css';

const Steps = ({ stepState, steps }) => {
  return steps.map((step, i) => (
    <WizardStep
      key={step.title}
      title={step.title}
      completed={step.completed}
      active={i === stepState}
    />
  ));
};

const Wizard = () => {
  const DURATION_25_MINUTES = 1500;

  const [project, setProject] = useState({
    name: '',
    pomodoro_duration: DURATION_25_MINUTES
  });

  const [stepState, setStepState] = useState(0);

  const [steps, setSteps] = useState([
    {
      title: 'Add a project',
      completed: false
    },
    {
      title: 'Activate notifications',
      completed: false
    },
    {
      title: 'Work work work',
      completed: false
    }
  ]);

  const history = useHistory();
  const dispatch = useDispatch();

  useEffect(() => {
    history.listen((newLocation, action) => {
      if (newLocation.pathname !== '/wizard' && project.name !== '') {
        return history.replace('/wizard');
      }
      if (action === 'POP') {
        if (stepState > 0) {
          setStepState(stepState - 1);
        }
        history.push('/wizard');
      }
    });
  }, []);

  const updateStepState = (index, completed = true) => {
    const copySteps = steps.slice();
    const step = copySteps[index];
    step.completed = completed;
    copySteps.splice(index, 1, step);
    setSteps(copySteps);
  };

  const onStart = () => {
    updateStepState(2);
    Pomodoros.firstTime(project)
      .then(r => {
        const pomodoro = r.data;
        const project = pomodoro.project;
        dispatch(changePomodoroDuration(pomodoro.duration));
        dispatch(selectPomodoroProject(project));
        dispatch(startPomodoroReducer(pomodoro));
        dispatch(startPomodoroSuccess(pomodoro));
      })
      .finally(() => history.push('/'));
  };

  const getStepScreen = step => {
    switch (step) {
      case 0:
        return (
          <AddProject
            project={project}
            onNext={project => {
              setProject(project);
              setStepState(stepState + 1);
              updateStepState(0);
            }}
          />
        );
      case 1:
        return (
          <ActivateNotifications
            onNext={() => {
              updateStepState(1);
              setStepState(stepState + 1);
            }}
          />
        );
      case 2:
        return <StartWork onStart={onStart} />;
      default:
        return <div>Error</div>;
    }
  };

  const StepScreen = getStepScreen(stepState);

  return (
    <MainContainer>
      <Step.Group stackable="tablet" style={{ width: '100%' }} ordered>
        <Steps
          steps={steps}
          stepState={stepState}
          onChange={n => setStepState(n)}
        />
      </Step.Group>
      <div className={styles.wrapper}>{StepScreen}</div>
    </MainContainer>
  );
};

export default Wizard;
