import {
  FETCH_PROJECT_SUCCESS,
  FETCH_PROJECTS_SUCCESS,
  UPDATE_PROJECT_SUCCESS,
  FETCH_SESSIONS_BY_PROJECT_SUCCESS,
  FETCH_SESSIONS_HEATMAP_BY_PROJECT_SUCCESS,
  UPDATE_CURRENT_PAGE_SESSIONS_BY_PROJECT,
  CLEAN_SESSIONS_BY_PROJECT,
  FETCH_SESSIONS_BY_PROJECT_START,
  FETCH_SESSIONS_BY_PROJECT_FAILURE,
  CHECK_PROJECT_NAME_START,
  CHECK_PROJECT_NAME_SUCCESS,
  CHECK_PROJECT_NAME_FAILURE,
  CLEAR_CHECK_PROJECT_NAME
} from './actions';

const initialState = {
  form: {
    name: { isFetching: false, value: '', isAvailable: true }
  },
  projects: [],
  project: {},
  sessions: { isFetching: false, currentPage: 1, numPages: 1, results: [] },
  heatmap: { max: -1, sessions: [] }
};

export default (state = initialState, { type, payload }) => {
  switch (type) {
    case 'SELECT_PROJECT':
      return { ...state, selectedProject: payload };
    case CLEAN_SESSIONS_BY_PROJECT:
      return {
        ...state,
        heatmap: initialState.heatmap,
        sessions: initialState.sessions
      };
    case FETCH_PROJECT_SUCCESS:
      return {
        ...state,
        project: payload
      };
    case FETCH_PROJECTS_SUCCESS:
      return { ...state, projects: payload };
    case UPDATE_PROJECT_SUCCESS:
      return { ...state, project: { ...state.project, ...payload } };
    case FETCH_SESSIONS_BY_PROJECT_FAILURE:
      return { ...state, sessions: { ...state.sessions, isFetching: false } };
    case FETCH_SESSIONS_BY_PROJECT_START:
      return { ...state, sessions: { ...state.sessions, isFetching: true } };
    case FETCH_SESSIONS_BY_PROJECT_SUCCESS:
      const { results, num_pages } = payload;
      return {
        ...state,
        sessions: {
          ...state.sessions,
          results,
          numPages: num_pages,
          isFetching: false
        }
      };
    case UPDATE_CURRENT_PAGE_SESSIONS_BY_PROJECT:
      return {
        ...state,
        sessions: { ...state.sessions, currentPage: payload }
      };
    case FETCH_SESSIONS_HEATMAP_BY_PROJECT_SUCCESS:
      return { ...state, heatmap: payload };
    case CHECK_PROJECT_NAME_START:
      return {
        ...state,
        form: {
          ...state.form,
          name: { ...state.form.name, isFetching: true, value: payload.name }
        }
      };
    case CHECK_PROJECT_NAME_SUCCESS:
      return {
        ...state,
        form: {
          ...state.form,
          name: {
            ...state.form.name,
            isFetching: false,
            value: payload.project_name,
            isAvailable: payload.is_available
          }
        }
      };
    case CHECK_PROJECT_NAME_FAILURE:
      return {
        ...state,
        form: {
          ...state.form,
          name: { ...state.form.name, isFetching: false }
        }
      };
    case CLEAR_CHECK_PROJECT_NAME:
      return { ...state, form: initialState.form };
    default:
      return state;
  }
};
