import { LOGIN_SUCCESS } from '../Authentication/actions';

const initialState = {
  error: { warned: true }
};

const getErrorMessage = e => {
  if (!e) {
    return 'Error';
  }
  if (!e.response) {
    return 'Network error';
  }
  const status = e.response.status;
  if (status >= 400 && status < 500) {
    return 'Client error';
  }
  if (status >= 500) {
    return 'Server error';
  }
};

export default (state = initialState, { type, payload }) => {
  if (type === LOGIN_SUCCESS) {
    return initialState;
  } else if (typeof type === 'string' && type.includes('FAILURE')) {
    const e = payload;
    let message = getErrorMessage(e);
    return {
      ...state,
      error: { ...state.error, error: e, message, warned: false }
    };
  } else if (type === 'ERROR_WARNED') {
    return { ...state, error: { ...state.error, warned: true } };
  } else {
    return state;
  }
};
