import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { Modal } from 'semantic-ui-react';
import {
  saveProject,
  checkProjectName,
  clearCheckProjectName
} from '../../actions';
import { getProjectNameForm } from '../../selectors';
import ProjectForm from '../../ProjectForm';
import { useToasts } from 'react-toast-notifications';
import { useHistory } from 'react-router-dom';

const CreateProjectModalContainer = ({ isOpen, close }) => {
  let projectNameTimer = null;

  const dispatch = useDispatch();
  const history = useHistory();
  const { addToast } = useToasts();

  const projectNameForm = useSelector(getProjectNameForm);

  const postSave = projectCreatedId => {
    addToast('Project created', {
      appearance: 'success',
      autoDismiss: true
    });
    close();
    history.push(`/projects/${projectCreatedId}`);
  };

  useEffect(() => {
    return () => dispatch(clearCheckProjectName());
  }, [dispatch]);

  /**
   * Wait until the user finish typping to
   * find if the project name exists
   * @param {string} projectName project name
   */
  const onProjectNameChange = projectName => {
    clearTimeout(projectNameTimer);
    projectNameTimer = setTimeout(() => {
      dispatch(checkProjectName(projectName));
    }, 400);
  };

  const closeModal = () => {
    close();
    dispatch(clearCheckProjectName());
  };

  return (
    <Modal size="small" open={isOpen} onClose={closeModal}>
      <ProjectForm
        saveButtonLabel="Create project"
        saveProject={project => {
          dispatch(saveProject(project, postSave));
          dispatch(clearCheckProjectName());
        }}
        onCancel={closeModal}
        projectName={projectNameForm}
        onProjectNameChange={onProjectNameChange}
      />
    </Modal>
  );
};

CreateProjectModalContainer.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  close: PropTypes.func.isRequired
};

export default CreateProjectModalContainer;
