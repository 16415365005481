import React from 'react';
import { useDispatch } from 'react-redux';
import styles from './styles.module.css';
import { Button } from 'semantic-ui-react';
import {
  startPomodoro,
  startBreak,
  cancelBreak,
  showConfirmCancelSession
} from '../actions';

const PomodoroButtonContainer = ({ pomodoroState }) => {
  const dispatch = useDispatch();
  let button = {
    text: '',
    action: {}
  };
  switch (pomodoroState) {
    case 'awaiting_pomodoro':
      button = {
        text: 'Start Pomodoro',
        action: startPomodoro()
      };
      break;
    case 'awaiting_break':
      button = {
        text: 'Break',
        action: startBreak()
      };
      break;
    case 'pomodoro_started':
      button = {
        text: 'Cancel Pomodoro',
        action: showConfirmCancelSession(true)
      };
      break;
    case 'break_started':
      button = {
        text: 'Cancel Break',
        action: cancelBreak()
      };
      break;
    default:
      break;
  }
  return (
    <Button className={styles.button} onClick={() => dispatch(button.action)}>
      {button.text}
    </Button>
  );
};

export default PomodoroButtonContainer;
